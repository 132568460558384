import React, { useState } from 'react';

const Upvideo = () => {
  const [videoSrc, setVideoSrc] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setVideoSrc(e.target.elements.videoUrl.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="videoUrl">
          Enter the video URL:
          <input
            type="text"
            id="videoUrl"
            name="videoUrl"
            placeholder="https://www.youtube.com/watch?v=[video_id]"
          />
        </label>
        <button type="submit">Embed Video</button>
      </form>
      {videoSrc && (
        <div>
          <iframe
            title="Embedded Video"
            width="560"
            height="315"
            src={videoSrc.replace('watch?v=', 'embed/')}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Upvideo;
