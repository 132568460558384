import React, { useState, useContext } from "react"
import {
  InputContainer,
  SelectContainer,
} from "../GlobalComponents/GlobalComponents.styles"

import { InputsContainer } from "./AddListingProcess.styles"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"

import Select from "react-select"

const Financial = ({
  afterRepairValue,
  rentPrice,
  salePrice,
  saleOrRent,
  currentRent,
  builtTax,
  propertyManagement,
  grossCashflow,
  capRate,
  insurance,
  isEdit,
  sale_or_rent_constants,
  arabic,
}) => {
  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  const {
    setAfterRepairValue,
    setCurrentRent,
    setBuiltTax,
    setSalePrice,
    setSaleOrRent,
    setRentPrice,
    setGrossCashFlow,
    setPropertyManagement,
    setCapRate,
    setInsurance,
  } = useContext(context)

  const [afterRepairValueFocus, setAfterRepairValueFocus] = useState(false)
  const [currentRentFocus, setCurrentRentFocus] = useState(false)
  const [builtTaxFocus, setBuiltTaxFocus] = useState(false)
  const [grossCashflowFocus, setGrossCashFlowFocus] = useState(false)
  const [propertyManagementFocus, setPropertyManagementFocus] = useState(false)
  const [capRateFocus, setCapRateFocus] = useState(false)
  const [insuranceFocus, setInsuranceFocus] = useState(false)
  const [rentOrSaleFocus, setRentOrSaleFocus] = useState(false)
  const [rentFocus, setRentFocus] = useState(false)
  const [saleFocus, setSaleFocus] = useState(false)
  const [currencyFocus, setCurrencyFocus] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState("USD")
  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  return (
    <InputsContainer>
      <div className="container">
        <h2>{arabic == false ? "Financial Details" : "التفاصيل المالية"}</h2>
        <div className="row">
          <div className="col-lg-6">
            <InputContainer htmlFor="repair" isFocus={afterRepairValueFocus}>
              <span>
                {arabic == false ? "After repair Value:" : "بعد قيمة الإصلاح:"}
              </span>
              <input
                type="number"
                id="repair"
                value={afterRepairValue}
                placeholder={
                  arabic == false
                    ? "Enter repair estimate"
                    : "أدخل تقدير الإصلاح"
                }
                onFocus={() => setAfterRepairValueFocus(true)}
                onBlur={() => setAfterRepairValueFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setAfterRepairValue(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <SelectContainer htmlFor="rentSale" isFocus={rentOrSaleFocus}>
              <div className="content">
                <span>
                  {arabic == false ? "Property Status:*" : "بيع أم إيجار؟ *"}
                </span>
                <Select
                  options={sale_or_rent_constants}
                  isSearchable={false}
                  isClearable={false}
                  value={
                    Object.keys(saleOrRent).length !== 0 ? saleOrRent : null
                  }
                  placeholder={
                    arabic == false
                      ? "Is the property for sale or rent?"
                      : "هل العقار معروض للبيع أم الإيجار؟"
                  }
                  id="rentSale"
                  styles={selectStyles}
                  onFocus={() => setRentOrSaleFocus(true)}
                  onBlur={() => setRentOrSaleFocus(false)}
                  onChange={e => {
                    setSalePrice("")
                    setRentPrice("")
                    setSaleOrRent({ label: e.label, value: e.value })
                  }}
                  dir={arabic == false ? " " : "rtl"}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.564"
                  height="8.818"
                  viewBox="0 0 14.564 8.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            <InputContainer>
              <label htmlFor="currency">
                {arabic ? "العملة: *" : "Currency:*"}
              </label>
              <select
                id="currency"
                name="currency"
                value={selectedCurrency}
                onFocus={() => setCurrencyFocus(true)}
                onBlur={() => setCurrencyFocus(false)}
                onChange={e => setSelectedCurrency(e.target.value)}
                aria-label={arabic ? "العملة" : "Currency"}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="AED">AED</option>
              </select>
            </InputContainer>

            {(saleOrRent.value === "rent" || saleOrRent.value === "both") && (
              <InputContainer htmlFor="rent" isFocus={rentFocus}>
                <span>
                  {arabic == false ? "Rent Price:*" : "بدل الإيجار: *"}
                </span>
                <input
                  type="text"
                  id="rent"
                  value={rentPrice}
                  placeholder={
                    arabic == false
                      ? `Enter rent price in ${selectedCurrency}`
                      : `أدخل سعر الإيجار بال${selectedCurrency}`
                  }
                  onFocus={() => setRentFocus(true)}
                  onBlur={() => setRentFocus(false)}
                  onChange={e => {
                    if (!(e.target.value < 0)) {
                      setRentPrice(e.target.value)
                    }
                  }}
                  dir={arabic == false ? " " : "rtl"}
                />
              </InputContainer>
            )}
            {(saleOrRent.value === "sale" || saleOrRent.value === "both") && (
              <InputContainer htmlFor="sale" isFocus={saleFocus}>
                <span>{arabic == false ? "Sale Price:*" : "سعر البيع:*"}</span>
                <input
                  type="text"
                  id="sale"
                  value={salePrice}
                  placeholder={
                    arabic == false ? "Enter sale price" : "أدخل السعر المخفَّض"
                  }
                  onFocus={() => setSaleFocus(true)}
                  onBlur={() => setSaleFocus(false)}
                  onChange={e => {
                    if (!(e.target.value < 0)) {
                      setSalePrice(e.target.value)
                    }
                  }}
                  dir={arabic == false ? " " : "rtl"}
                />
              </InputContainer>
            )}
            <InputContainer htmlFor="current" isFocus={currentRentFocus}>
              <span>
                {arabic == false ? "Current Rent:" : "الإيجار الحالي:"}
              </span>
              <input
                type="text"
                id="current"
                value={currentRent}
                placeholder={
                  arabic == false ? "Enter current rent" : "أدخل الإيجار الحالي"
                }
                onFocus={() => setCurrentRentFocus(true)}
                onBlur={() => setCurrentRentFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setCurrentRent(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="built" isFocus={builtTaxFocus}>
              <span>
                {arabic == false
                  ? "Built Property Tax:"
                  : "ضريبة الأملاك المبنية:"}
              </span>
              <input
                type="number"
                id="built"
                value={builtTax}
                placeholder={
                  arabic == false
                    ? "Enter built property tax"
                    : "أدخل ضريبة الأملاك المبنية"
                }
                onFocus={() => setBuiltTaxFocus(true)}
                onBlur={() => setBuiltTaxFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setBuiltTax(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
          <div className="col-lg-6">
            <SelectContainer htmlFor="insurance" isFocus={insuranceFocus}>
              <div className="content">
                <span>{arabic == false ? "Insurance:" : "تأمين:"}</span>
                <Select
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                  isSearchable={false}
                  isClearable={false}
                  value={Object.keys(insurance).length !== 0 ? insurance : null}
                  placeholder={
                    arabic == false
                      ? "Is the property for sale or rent?"
                      : "هل العقار معروض للبيع أم الإيجار؟"
                  }
                  id="insurance"
                  styles={selectStyles}
                  onFocus={() => setInsuranceFocus(true)}
                  onBlur={() => setInsuranceFocus(false)}
                  onChange={e =>
                    setInsurance({ label: e.label, value: e.value })
                  }
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.564"
                  height="8.818"
                  viewBox="0 0 14.564 8.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            <InputContainer
              htmlFor="propManag"
              isFocus={propertyManagementFocus}
            >
              <span>
                {arabic == false ? "Property Management:" : "إدارة الممتلكات:"}
              </span>
              <input
                type="number"
                id="propManag"
                value={propertyManagement}
                placeholder={
                  arabic == false
                    ? "Enter property managment"
                    : "أدخل إدارة الممتلكات"
                }
                onFocus={() => setPropertyManagementFocus(true)}
                onBlur={() => setPropertyManagementFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setPropertyManagement(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="grossCash" isFocus={grossCashflowFocus}>
              <span>
                {arabic == false
                  ? "Gross Cashflow:"
                  : "التدفق النقدي الإجمالي:"}
              </span>
              <input
                type="number"
                id="grossCash"
                value={grossCashflow}
                placeholder={
                  arabic == false
                    ? "Enter Gross Cashflow"
                    : "أدخل إجمالي التدفق النقدي"
                }
                onFocus={() => setGrossCashFlowFocus(true)}
                onBlur={() => setGrossCashFlowFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setGrossCashFlow(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="cap" isFocus={capRateFocus}>
              <span>{arabic == false ? "CAP Rate:" : "معدل CAP:"}</span>
              <input
                type="number"
                id="cap"
                value={capRate}
                placeholder={
                  arabic == false
                    ? "Enter Gross Cashflow"
                    : "أدخل إجمالي التدفق النقدي"
                }
                onFocus={() => setCapRateFocus(true)}
                onBlur={() => setCapRateFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0)) {
                    setCapRate(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default Financial
