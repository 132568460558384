import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GlobalStyle from "../../assets/globalStyles"
import { useAuthContext } from "../../hooks/useAuthContext"
import AddLisitngHeader from "../Header/AddLisitngHeader"
import "./AddListingLayout.css"

const AddLisitngLayout = ({ children }) => {
  return (
    <>
      <div className="image-overlay"></div>
      <StaticImage
        src="../../assets/images/modern-villa.png"
        alt="modern villa"
        layout="fullWidth"
        placeholder="tracedSVG"
        className="main-background-image"
      ></StaticImage>
      <div className="main-content">
        <GlobalStyle />

        <AddLisitngHeader />
        <main>{children}</main>
      </div>
    </>
  )
}

export default AddLisitngLayout
