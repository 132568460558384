import React from 'react'

import { Content, LogoContainer } from './Header.styles'
import Logo from '../../assets/images/logo.svg';

const AddLisitngHeader = () => {
    return (
        <header>
            <div className='container'>
                <div className="row p-0">
                    <div className="col-lg-8 d-flex align-items-center justify-content-between">
                        <Content>
                            <LogoContainer>
                                <img src={Logo} alt="Logo" />
                                <p>PROPERTY <span>DEALS</span></p>
                            </LogoContainer>
                        </Content>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default AddLisitngHeader