import React, { useState, useContext } from "react"
import { FileUploader } from "react-drag-drop-files"

import {
  Gallery,
  GalleryUploader,
  InputsContainer,
} from "./AddListingProcess.styles"
import {
  InputContainer,
  SelectContainer,
  TextareaContainer,
} from "../GlobalComponents/GlobalComponents.styles"

import Select from "react-select"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"
import { deleteObject, ref } from "firebase/storage"
import { db, storage } from "../../firebase/config"
import { doc, getDoc } from "firebase/firestore"
import Upvideo from "./video/Upvideo"

const Information = ({
  listingTitle,
  propertyTypeSubcategory,
  propertyType,
  description,
  featuredImage,
  featuredImagePath,
  galleryImages,
  galleryImagesPaths,
  isEdit,
  property_type_constants,
  listingplacedBy,
  arabic,
}) => {
  // for focusing

  const [titleFocus, setTitleFocus] = useState(false)
  const [propertyTypeFocus, setPropertyTypeFocus] = useState(false)
  const [propertyTypeSubcategoryFocus, setPropertySubcategoryFocus] =
    useState(false)
  const [descriptionFocus, setDescriptionFocus] = useState(false)
  const [listingplacedByFocus, setlistingplacedByFocus] = useState(false)

  // initializing the propertyTypeSubcategory
  const [
    propertyTypeSubcategoryConstants,
    setPropertyTypeSubcategoryConstants,
  ] = useState([])

  // to add a random name
  function randomName() {
    var text = ""
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }

  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  // useContext states
  const {
    setListingTitle,
    setPropertyTypeSubcategory,
    setPropertyType,
    setDescription,
    setFeaturedImage,
    setFeaturedImagePath,
    setGalleryImages,
    setGalleryImagesPaths,
    setIsLoading,
    setListingplacedBy,
  } = useContext(context)

  //for handling file upload
  const uploadThumbnailHandler = file => {
    setFeaturedImage(file)
    const random = randomName()
    setFeaturedImagePath(`images/property/thumbnail/${random}${file.name}`)
  }

  // for uploading gallery
  const uploadGalleryHandler = files => {
    let processed = 0
    if (files.length + galleryImages.length > 5) {
      alert("You can only insert 5 images")
      return
    }
    const oldFiles = [...galleryImages]
    const oldPaths = [...galleryImagesPaths]
    const newFiles = [...files]

    newFiles.forEach(file => {
      oldFiles.push(file)
      const random = randomName()
      oldPaths.push(`images/property/gallery/${random}${file.name}`)
      processed++
      if (processed === files.length) {
        setGalleryImages(oldFiles)
        setGalleryImagesPaths(oldPaths)
      }
    })
  }

  // for deleting thumbnail
  const deleteThumbnail = () => {
    setIsLoading(true)
    if (featuredImage.name) {
      setFeaturedImage(null)
      setFeaturedImagePath("")
    } else {
      deleteObject(ref(storage, featuredImagePath)).then(() => {
        setFeaturedImage(null)
        setFeaturedImagePath("")
      })
    }
    setIsLoading(false)
  }

  // for deleting gallery
  const deleteGalleryFile = index => {
    if (galleryImages[index].name) {
      const fileArray = [...galleryImages]
      const pathArray = [...galleryImagesPaths]
      fileArray.splice(index, 1)
      pathArray.splice(index, 1)
      setGalleryImages(fileArray)
      setGalleryImagesPaths(pathArray)
    } else {
      deleteObject(ref(storage, galleryImagesPaths[index])).then(() => {
        const fileArray = [...galleryImages]
        const pathArray = [...galleryImagesPaths]
        fileArray.splice(index, 1)
        pathArray.splice(index, 1)
        setGalleryImages(fileArray)
        setGalleryImagesPaths(pathArray)
      })
    }
  }

  // setting the file types accepted for upload
  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"]

  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  const type_constants = property_type_constants.map(type => {
    return { value: type.id, label: type.data.title }
  })

  // handle for property type change
  const propertyTypeChange = e => {
    setPropertyTypeSubcategory({})
    getDoc(doc(db, "property_type", e.value)).then(document => {
      if (document.data()) {
        setPropertyTypeSubcategoryConstants(document.data().subcategories)
      }
      setPropertyType({ value: e.value, label: e.label })
    })
  }

  return (
    <InputsContainer>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>
              {arabic == false ? "Property Information" : "معلومات العقار"}
            </h2>
            <InputContainer htmlFor="title" isFocus={titleFocus}>
              <span>Placed by:*</span>
              <input
                type="text"
                id="placedBy"
                value={listingplacedBy}
                placeholder={
                  arabic == false
                    ? "Enter who placed the listing"
                    : "أدخل من وضع الإعلان"
                }
                onFocus={() => setlistingplacedByFocus(true)}
                onBlur={() => setlistingplacedByFocus(false)}
                onChange={e => setListingplacedBy(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="title" isFocus={titleFocus}>
              <span>Title:*</span>
              <input
                type="text"
                id="title"
                value={listingTitle}
                placeholder={
                  arabic == false
                    ? "Enter property title"
                    : "ادخل عنوان الإعلان"
                }
                onFocus={() => setTitleFocus(true)}
                onBlur={() => setTitleFocus(false)}
                onChange={e => setListingTitle(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <TextareaContainer htmlFor="description" isFocus={descriptionFocus}>
              <span>{arabic == false ? "Description:*" : "وصف:*"}</span>
              <textarea
                placeholder={
                  arabic == false
                    ? "Enter property description"
                    : "ادخل وصف العقار"
                }
                onChange={e => setDescription(e.target.value)}
                value={description ? description : ""}
                onFocus={() => setDescriptionFocus(true)}
                onBlur={() => setDescriptionFocus(false)}
                rows={10}
                dir={arabic == false ? " " : "rtl"}
              ></textarea>
            </TextareaContainer>
          </div>
          <div className="col-lg-6">
            <GalleryUploader>
              <div className="featured-image-upload">
                <h2>
                  {arabic == false
                    ? "Upload Featured Image:*"
                    : "تحميل الصورة المميزة: *"}
                </h2>
                <FileUploader
                  classes="dropzone"
                  handleChange={uploadThumbnailHandler}
                  name="file"
                  types={fileTypes}
                />
                <Gallery>
                  {featuredImage && (
                    <div
                      className="thumbnail-container"
                      onClick={deleteThumbnail}
                    >
                      <div className="thumbnail-overlay">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.531"
                          height="7.619"
                          viewBox="0 0 6.531 7.619"
                        >
                          <path
                            id="Path_185"
                            data-name="Path 185"
                            d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                            transform="translate(-0.003 -31.981)"
                          />
                        </svg>
                      </div>
                      <img
                        src={
                          featuredImage.name
                            ? URL.createObjectURL(featuredImage)
                            : featuredImage
                        }
                      />
                    </div>
                  )}
                </Gallery>
              </div>
            </GalleryUploader>

            <GalleryUploader>
              <h2>
                {arabic == false ? "Upload Gallery Images" : "تحميل صور المعرض"}
              </h2>
              <FileUploader
                multiple={true}
                classes="dropzone"
                handleChange={uploadGalleryHandler}
                name="file"
                types={fileTypes}
              />
              {
                <Gallery galleryImages={galleryImages}>
                  {galleryImages.map((image, index) => {
                    return (
                      <div
                        className="thumbnail-container"
                        key={index}
                        onClick={() => {
                          deleteGalleryFile(index)
                        }}
                      >
                        <div className="thumbnail-overlay">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.531"
                            height="7.619"
                            viewBox="0 0 6.531 7.619"
                          >
                            <path
                              id="Path_185"
                              data-name="Path 185"
                              d="M6.407,38.707a.544.544,0,1,1-.836.7l-2.3-2.778-2.3,2.763a.544.544,0,1,1-.836-.7l2.43-2.917-2.431-2.9a.544.544,0,0,1,.836-.7l2.3,2.763,2.3-2.763a.544.544,0,1,1,.836.7L3.977,35.79Z"
                              transform="translate(-0.003 -31.981)"
                            />
                          </svg>
                        </div>
                        <img
                          src={image.name ? URL.createObjectURL(image) : image}
                        />
                      </div>
                    )
                  })}
                </Gallery>
              }
            </GalleryUploader>
            <Upvideo />
            <SelectContainer
              htmlFor="property_type"
              isFocus={propertyTypeFocus}
            >
              <div className="content">
                <span>{arabic == false ? "*" : "نوع الملكية*"}</span>
                <Select
                  options={type_constants}
                  isSearchable={false}
                  isClearable={false}
                  value={
                    Object.keys(propertyType).length !== 0 ? propertyType : null
                  }
                  placeholder={
                    arabic == false
                      ? "Select Property Type"
                      : "حدد نوع الملكية  "
                  }
                  id="property_type"
                  styles={selectStyles}
                  onFocus={() => setPropertyTypeFocus(true)}
                  onBlur={() => setPropertyTypeFocus(false)}
                  onChange={e => propertyTypeChange(e)}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            {Object.keys(propertyType).length !== 0 && (
              <SelectContainer
                htmlFor="property_type_subcategory"
                isFocus={propertyTypeSubcategoryFocus}
              >
                <div className="content">
                  <span>
                    {arabic == false
                      ? "Property Subcategory*"
                      : "فئة الملكية الفرعية *"}
                  </span>
                  <Select
                    options={propertyTypeSubcategoryConstants}
                    isSearchable={false}
                    isClearable={false}
                    value={
                      Object.keys(propertyTypeSubcategory).length !== 0
                        ? propertyTypeSubcategory
                        : null
                    }
                    placeholder={
                      arabic == false
                        ? "Select Property Type Subcategory"
                        : "حدد فئة نوع الملكية فرعية  "
                    }
                    id="property_type_subcategory"
                    styles={selectStyles}
                    onFocus={() => setPropertySubcategoryFocus(true)}
                    onBlur={() => setPropertySubcategoryFocus(false)}
                    onChange={e => {
                      setPropertyTypeSubcategory({
                        value: e.value,
                        label: e.label,
                      })
                    }}
                  />
                </div>
                <div className="arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.564"
                    height="7.818"
                    viewBox="0 0 13.564 7.818"
                  >
                    <path
                      id="XMLID_225_"
                      d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                      transform="translate(0.564 -74.5)"
                      fill="#477b11"
                      stroke="#477b11"
                      strokeWidth="1"
                    />
                  </svg>
                </div>
              </SelectContainer>
            )}
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default Information
