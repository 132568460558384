import React, { useState, useContext } from "react"

import { InputsContainer } from "./AddListingProcess.styles"

import {
  SelectContainer,
  InputContainer,
} from "../GlobalComponents/GlobalComponents.styles"

import Select from "react-select"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"

const HotelsOrResorts = ({
  numOfFloors,
  numOfReceptionStations,
  numOfAdminstrationOffices,
  numOfKinglslySuits,
  areaOfKingslySuitInSMetersOrSFeet,
  numOfRegularSuits,
  areaOfRegularSuitsInSMetersOrSFeet,
  numOfSingleBedroomsWithBath,
  areaOfSingleBedroomsWithBathInSMetersOrSFeet,
  numOfDoubleBedroomsWithBath,
  areaOfDoubleBedroomsWithBathInSMetersOrSFeet,
  numOfChaletsOrSeparateHouses,
  areaOfChaletsOrSeparateousesInSMetersOrSFeet,
  numOfCarParkingLots,
  numOfLifts,
  numOfRestaurantsAndCoffeeShops,
  secutriyCamerasAndGaurds,
  availabilityOfWater,
  availabiltyOfInternetService,
  availabilityOfGardens,
  numOfInsideSwimmingPoolsAndShowers,
  numOfOpenAirSwimmingPoolsAndShowers,
  numOfElectricGenerators,
  numOfConferenceRooms,
  numOfLounges,
  numOfWeddingHalls,
  numOfMeetingHalls,
  numOfConferenceHalls,
  numOfStaffRooms,
  isEdit,
  arabic,
}) => {
  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  const {
    setNumOfFloors,
    setNumOfReceptionStations,
    setNumOfAdminstrationOffices,
    setNumOfKinglslySuits,
    setAreaOfKingslySuitInSMetersOrSFeet,
    setNumOfRegularSuits,
    setAreaOfRegularSuitsInSMetersOrSFeet,
    setNumOfSingleBedroomsWithBath,
    setAreaOfSingleBedroomsWithBathInSMetersOrSFeet,
    setNumOfDoubleBedroomsWithBath,
    setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet,
    setNumOfChaletsOrSeparateHouses,
    setAreaOfChaletsOrSeparateousesInSMetersOrSFeet,
    setNumOfCarParkingLots,
    setNumOfLifts,
    setNumOfRestaurantsAndCoffeeShops,
    setSecutriyCamerasAndGaurds,
    setAvailabilityOfWater,
    setAvailabiltyOfInternetService,
    setAvailabilityOfGardens,
    setNumOfInsideSwimmingPoolsAndShowers,
    setNumOfOpenAirSwimmingPoolsAndShowers,
    setNumOfElectricGenerators,
    setNumOfConferenceRooms,
    setNumOfLounges,
    setNumOfWeddingHalls,
    setNumOfMeetingHalls,
    setNumOfConferenceHalls,
    setNumOfStaffRooms,
  } = useContext(context)

  // for focusing
  const [numOfFloorsFocus, setNumOfFloorsFocus] = useState(false)
  const [numOfReceptionStationsFocus, setNumOfReceptionStationsFocus] =
    useState(false)
  const [numOfAdminstrationOfficesFocus, setNumOfAdminstrationOfficesFocus] =
    useState(false)
  const [numOfKinglslySuitsFocus, setNumOfKinglslySuitsFocus] = useState(false)
  const [
    areaOfKingslySuitInSMetersOrSFeetFocus,
    setAreaOfKingslySuitInSMetersOrSFeetFocus,
  ] = useState(false)
  const [numOfRegularSuitsFocus, setNumOfRegularSuitsFocus] = useState(false)
  const [
    areaOfRegularSuitsInSMetersOrSFeetFocus,
    setAreaOfRegularSuitsInSMetersOrSFeetFocus,
  ] = useState(false)
  const [
    numOfSingleBedroomsWithBathFocus,
    setNumOfSingleBedroomsWithBathFocus,
  ] = useState(false)
  const [
    areaOfSingleBedroomsWithBathInSMetersOrSFeetFocus,
    setAreaOfSingleBedroomsWithBathInSMetersOrSFeetFocus,
  ] = useState(false)
  const [
    numOfDoubleBedroomsWithBathFocus,
    setNumOfDoubleBedroomsWithBathFocus,
  ] = useState(false)
  const [
    areaOfDoubleBedroomsWithBathInSMetersOrSFeetFocus,
    setAreaOfDoubleBedroomsWithBathInSMetersOrSFeetFocus,
  ] = useState(false)
  const [
    numOfChaletsOrSeparateHousesFocus,
    setNumOfChaletsOrSeparateHousesFocus,
  ] = useState(false)
  const [
    areaOfChaletsOrSeparateousesInSMetersOrSFeetFocus,
    setAreaOfChaletsOrSeparateousesInSMetersOrSFeetFocus,
  ] = useState(false)
  const [numOfCarParkingLotsFocus, setNumOfCarParkingLotsFocus] =
    useState(false)
  const [numOfLiftsFocus, setNumOfLiftsFocus] = useState(false)
  const [
    numOfRestaurantsAndCoffeeShopsFocus,
    setNumOfRestaurantsAndCoffeeShopsFocus,
  ] = useState(false)
  const [secutriyCamerasAndGaurdsFocus, setSecutriyCamerasAndGaurdsFocus] =
    useState(false)
  const [availabilityOfWaterFocus, setAvailabilityOfWaterFocus] =
    useState(false)
  const [
    availabiltyOfInternetServiceFocus,
    setAvailabiltyOfInternetServiceFocus,
  ] = useState(false)
  const [availabilityOfGardensFocus, setAvailabilityOfGardensFocus] =
    useState(false)
  const [
    numOfInsideSwimmingPoolsAndShowersFocus,
    setNumOfInsideSwimmingPoolsAndShowersFocus,
  ] = useState(false)
  const [
    numOfOpenAirSwimmingPoolsAndShowersFocus,
    setNumOfOpenAirSwimmingPoolsAndShowersFocus,
  ] = useState(false)
  const [numOfElectricGeneratorsFocus, setNumOfElectricGeneratorsFocus] =
    useState(false)
  const [numOfConferenceRoomsFocus, setNumOfConferenceRoomsFocus] =
    useState(false)
  const [numOfLoungesFocus, setNumOfLoungesFocus] = useState(false)
  const [numOfWeddingHallsFocus, setNumOfWeddingHallsFocus] = useState(false)
  const [numOfMeetingHallsFocus, setNumOfMeetingHallsFocus] = useState(false)
  const [numOfConferenceHallsFocus, setNumOfConferenceHallsFocus] =
    useState(false)
  const [numOfStaffRoomsFocus, setNumOfStaffRoomsFocus] = useState(false)

  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  return (
    <InputsContainer>
      <div className="container">
        <h2>
          {arabic == false
            ? "Hotel Or Resort Details"
            : "تفاصيل الفندق أو المنتجع"}
        </h2>
        <div className="row">
          <div className="col-lg-6">
            <InputContainer htmlFor="numOfFloors" isFocus={numOfFloorsFocus}>
              <span>
                {arabic == false ? "Number of Floors:*" : "عدد الطوابق:*"}
              </span>
              <input
                type="text"
                id="numOfFloors"
                value={numOfFloors}
                placeholder={
                  arabic == false
                    ? "Enter number of floors"
                    : "أدخل عدد الطوابق"
                }
                onFocus={() => setNumOfFloorsFocus(true)}
                onBlur={() => setNumOfFloorsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfFloors(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfReceptionStations"
              isFocus={numOfReceptionStationsFocus}
            >
              <span>
                {arabic == false
                  ? "Number of Reception Stations:*"
                  : "عدد محطات الاستقبال: *"}
              </span>
              <input
                type="text"
                id="numOfReceptionStations"
                value={numOfReceptionStations}
                placeholder={
                  arabic == false
                    ? "Enter number Reception Stations"
                    : "أدخل محطات الاستقبال"
                }
                onFocus={() => setNumOfReceptionStationsFocus(true)}
                onBlur={() => setNumOfReceptionStationsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfReceptionStations(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfAdminstrationOffices"
              isFocus={numOfAdminstrationOfficesFocus}
            >
              <span>
                {arabic == false
                  ? "Number of Administration Offices:*"
                  : "عدد مكاتب الإدارة: *"}
              </span>
              <input
                type="text"
                id="numOfAdminstrationOffices"
                value={numOfAdminstrationOffices}
                placeholder={
                  arabic == false
                    ? "Enter number of administration offices"
                    : "أدخل عدد مكاتب الإدارة"
                }
                onFocus={() => setNumOfAdminstrationOfficesFocus(true)}
                onBlur={() => setNumOfAdminstrationOfficesFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfAdminstrationOffices(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfKinglslySuits"
              isFocus={numOfKinglslySuitsFocus}
            >
              <span>
                {arabic == false
                  ? "Number of kingsly suits:*"
                  : "عدد الدعاوى الملكية: *"}
              </span>
              <input
                type="text"
                id="numOfKinglslySuits"
                value={numOfKinglslySuits}
                placeholder={
                  arabic == false
                    ? "Enter number of kingsly suits"
                    : "أدخل عدد الدعاوى الملكية"
                }
                onFocus={() => setNumOfKinglslySuitsFocus(true)}
                onBlur={() => setNumOfKinglslySuitsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfKinglslySuits(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="areaOfKingslySuitInSMetersOrSFeet"
              isFocus={areaOfKingslySuitInSMetersOrSFeetFocus}
            >
              <span>
                {arabic == false
                  ? "Area Of KingslySuit In Meters Or Feet:*"
                  : ": *"}
              </span>
              <input
                type="text"
                id="areaOfKingslySuitInSMetersOrSFeet"
                value={areaOfKingslySuitInSMetersOrSFeet}
                placeholder={
                  arabic == false
                    ? "Enter area Of KingslySuit In Meters Or Feet"
                    : ""
                }
                onFocus={() => setAreaOfKingslySuitInSMetersOrSFeetFocus(true)}
                onBlur={() => setAreaOfKingslySuitInSMetersOrSFeetFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setAreaOfKingslySuitInSMetersOrSFeet(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfRegularSuits"
              isFocus={numOfRegularSuitsFocus}
            >
              <span>
                {arabic == false ? "Number of Regular Suits:*<" : ": *"}
              </span>
              <input
                type="text"
                id="numOfRegularSuits"
                value={numOfRegularSuits}
                placeholder={
                  arabic == false ? "Enter Number of Regular Suits" : ""
                }
                onFocus={() => setNumOfRegularSuitsFocus(true)}
                onBlur={() => setNumOfRegularSuitsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfRegularSuits(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="areaOfRegularSuitsInSMetersOrSFeet"
              isFocus={areaOfRegularSuitsInSMetersOrSFeetFocus}
            >
              <span>
                {arabic == false
                  ? "Area Of Regular Suits In Meters Or Feet:*"
                  : "عدد الطوابق:*"}
              </span>
              <input
                type="text"
                id="areaOfRegularSuitsInSMetersOrSFeet"
                value={areaOfRegularSuitsInSMetersOrSFeet}
                placeholder={
                  arabic == false
                    ? "Enter Area Of Regular Suits In Meters Or Feet"
                    : ""
                }
                onFocus={() => setAreaOfRegularSuitsInSMetersOrSFeetFocus(true)}
                onBlur={() => setAreaOfRegularSuitsInSMetersOrSFeetFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setAreaOfRegularSuitsInSMetersOrSFeet(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfSingleBedroomsWithBath"
              isFocus={numOfSingleBedroomsWithBathFocus}
            >
              <span>
                {arabic == false ? "Num Of Single Bedrooms With Bath:*" : ":*"}
              </span>
              <input
                type="text"
                id="numOfSingleBedroomsWithBath"
                value={numOfSingleBedroomsWithBath}
                placeholder={
                  arabic == false ? "Num Of Single Bedrooms With Bath" : ""
                }
                onFocus={() => setNumOfSingleBedroomsWithBathFocus(true)}
                onBlur={() => setNumOfSingleBedroomsWithBathFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumOfSingleBedroomsWithBath(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="aparareaOfSingleBedroomsWithBathInSMetersOrSFeettments"
              isFocus={areaOfSingleBedroomsWithBathInSMetersOrSFeetFocus}
            >
              <span>
                {arabic == false
                  ? "Area Of Single Bedrooms With Bath In Meters Or Feet:*"
                  : ": *"}
              </span>
              <input
                type="text"
                id="areaOfSingleBedroomsWithBathInSMetersOrSFeet"
                value={areaOfSingleBedroomsWithBathInSMetersOrSFeet}
                placeholder={
                  arabic == false
                    ? "Area Of Single Bedrooms With Bath In Meters Or Feet"
                    : ""
                }
                onFocus={() =>
                  setAreaOfSingleBedroomsWithBathInSMetersOrSFeetFocus(true)
                }
                onBlur={() =>
                  setAreaOfSingleBedroomsWithBathInSMetersOrSFeetFocus(false)
                }
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setAreaOfSingleBedroomsWithBathInSMetersOrSFeet(
                      e.target.value
                    )
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfDoubleBedroomsWithBath"
              isFocus={numOfDoubleBedroomsWithBathFocus}
            >
              <span>
                {arabic == false ? "Num Of Double Bedrooms With Bath:" : ":"}
              </span>
              <input
                type="text"
                id="numOfDoubleBedroomsWithBath"
                value={numOfDoubleBedroomsWithBath}
                placeholder={
                  arabic == false ? "Num Of Double Bedrooms With Bath" : ""
                }
                onFocus={() => setNumOfDoubleBedroomsWithBathFocus(true)}
                onBlur={() => setNumOfDoubleBedroomsWithBathFocus(false)}
                onChange={e => {
                  setNumOfDoubleBedroomsWithBath(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>

            <InputContainer
              htmlFor="areaOfDoubleBedroomsWithBathInSMetersOrSFeet"
              isFocus={areaOfDoubleBedroomsWithBathInSMetersOrSFeetFocus}
            >
              <span>
                {arabic == false
                  ? "Area Of Double Bedrooms With Bath In Meters Or Feet:"
                  : ":"}
              </span>
              <input
                type="text"
                id="areaOfDoubleBedroomsWithBathInSMetersOrSFeet"
                value={areaOfDoubleBedroomsWithBathInSMetersOrSFeet}
                placeholder={
                  arabic == false
                    ? "Area Of Double Bedrooms With Bath In Meters Or Feet"
                    : ""
                }
                onFocus={() =>
                  setAreaOfDoubleBedroomsWithBathInSMetersOrSFeetFocus(true)
                }
                onBlur={() =>
                  setAreaOfDoubleBedroomsWithBathInSMetersOrSFeetFocus(false)
                }
                onChange={e => {
                  setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet(
                    e.target.value
                  )
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>

            <InputContainer
              htmlFor="numOfChaletsOrSeparateHouses"
              isFocus={numOfChaletsOrSeparateHousesFocus}
            >
              <span>
                {arabic == false ? "Num Of Chalets Or Separate Houses:" : ":"}
              </span>
              <input
                type="text"
                id="numOfChaletsOrSeparateHouses"
                value={numOfChaletsOrSeparateHouses}
                placeholder={
                  arabic == false ? "Num Of Chalets Or Separate Houses" : ""
                }
                onFocus={() => setNumOfChaletsOrSeparateHousesFocus(true)}
                onBlur={() => setNumOfChaletsOrSeparateHousesFocus(false)}
                onChange={e => {
                  setNumOfChaletsOrSeparateHouses(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="areaOfChaletsOrSeparateousesInSMetersOrSFeet"
              isFocus={areaOfChaletsOrSeparateousesInSMetersOrSFeetFocus}
            >
              <span>
                {arabic == false
                  ? "Area Of Chalets Or Separate ouses In Meters Or Feet:"
                  : ":"}
              </span>
              <input
                type="text"
                id="areaOfChaletsOrSeparateousesInSMetersOrSFeet"
                value={areaOfChaletsOrSeparateousesInSMetersOrSFeet}
                placeholder={
                  arabic == false
                    ? "Area Of Chalets Or Separate ouses In Meters Or Feet"
                    : ""
                }
                onFocus={() =>
                  setAreaOfChaletsOrSeparateousesInSMetersOrSFeetFocus(true)
                }
                onBlur={() =>
                  setAreaOfChaletsOrSeparateousesInSMetersOrSFeetFocus(false)
                }
                onChange={e => {
                  setAreaOfChaletsOrSeparateousesInSMetersOrSFeet(
                    e.target.value
                  )
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfCarParkingLots"
              isFocus={numOfCarParkingLotsFocus}
            >
              <span>{arabic == false ? "Num Of Car Parking Lots:" : ":"}</span>
              <input
                type="text"
                id="numOfCarParkingLots"
                value={numOfCarParkingLots}
                placeholder={arabic == false ? "Num Of Car Parking Lots" : ""}
                onFocus={() => setNumOfCarParkingLotsFocus(true)}
                onBlur={() => setNumOfCarParkingLotsFocus(false)}
                onChange={e => {
                  setNumOfCarParkingLots(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
          <div className="col-lg-6">
            
            <InputContainer htmlFor="numOfLifts" isFocus={numOfLiftsFocus}>
              <span>{arabic == false ? "Num Of Lifts:" : ":"}</span>
              <input
                type="text"
                id="numOfLifts"
                value={numOfLifts}
                placeholder={arabic == false ? "Num Of Lifts" : ""}
                onFocus={() => setNumOfLiftsFocus(true)}
                onBlur={() => setNumOfLiftsFocus(false)}
                onChange={e => {
                  setNumOfLifts(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfRestaurantsAndCoffeeShops"
              isFocus={numOfRestaurantsAndCoffeeShopsFocus}
            >
              <span>
                {arabic == false ? "Num Of Restaurants And Coffee Shops:" : ":"}
              </span>
              <input
                type="text"
                id="numOfRestaurantsAndCoffeeShops"
                value={numOfRestaurantsAndCoffeeShops}
                placeholder={
                  arabic == false ? "Num Of Restaurants And Coffee Shops" : ""
                }
                onFocus={() => setNumOfRestaurantsAndCoffeeShopsFocus(true)}
                onBlur={() => setNumOfRestaurantsAndCoffeeShopsFocus(false)}
                onChange={e => {
                  setNumOfRestaurantsAndCoffeeShops(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="secutriyCamerasAndGaurds"
              isFocus={secutriyCamerasAndGaurdsFocus}
            >
              <span>
                {arabic == false ? "Secutriy Cameras And Gaurds:" : ":"}
              </span>
              <input
                type="text"
                id="secutriyCamerasAndGaurds"
                value={secutriyCamerasAndGaurds}
                placeholder={
                  arabic == false ? "Secutriy Cameras And Gaurds" : ""
                }
                onFocus={() => setSecutriyCamerasAndGaurdsFocus(true)}
                onBlur={() => setSecutriyCamerasAndGaurdsFocus(false)}
                onChange={e => {
                  setSecutriyCamerasAndGaurds(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="availabilityOfWater"
              isFocus={availabilityOfWaterFocus}
            >
              <span>{arabic == false ? "Availability Of Water:" : ":"}</span>
              <input
                type="text"
                id="availabilityOfWater"
                value={availabilityOfWater}
                placeholder={arabic == false ? "Availability Of Water" : ""}
                onFocus={() => setAvailabilityOfWaterFocus(true)}
                onBlur={() => setAvailabilityOfWaterFocus(false)}
                onChange={e => {
                  setAvailabilityOfWater(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="availabiltyOfInternetService"
              isFocus={availabiltyOfInternetServiceFocus}
            >
              <span>
                {arabic == false ? "Availabilty Of Internet Service:" : ":"}
              </span>
              <input
                type="text"
                id="availabiltyOfInternetService"
                value={availabiltyOfInternetService}
                placeholder={
                  arabic == false ? "Availabilty Of Internet Service" : ""
                }
                onFocus={() => setAvailabiltyOfInternetServiceFocus(true)}
                onBlur={() => setAvailabiltyOfInternetServiceFocus(false)}
                onChange={e => {
                  setAvailabiltyOfInternetService(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="availabilityOfGardens"
              isFocus={availabilityOfGardensFocus}
            >
              <span>{arabic == false ? "Availability Of Gardens:" : ":"}</span>
              <input
                type="text"
                id="availabilityOfGardens"
                value={availabilityOfGardens}
                placeholder={arabic == false ? "Availability Of Gardens" : ""}
                onFocus={() => setAvailabilityOfGardensFocus(true)}
                onBlur={() => setAvailabilityOfGardensFocus(false)}
                onChange={e => {
                  setAvailabilityOfGardens(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfInsideSwimmingPoolsAndShowers"
              isFocus={numOfInsideSwimmingPoolsAndShowersFocus}
            >
              <span>
                {arabic == false
                  ? "Num Of Inside Swimming Pools And Showers:"
                  : ":"}
              </span>
              <input
                type="text"
                id="numOfInsideSwimmingPoolsAndShowers"
                value={numOfInsideSwimmingPoolsAndShowers}
                placeholder={
                  arabic == false
                    ? "Num Of Inside Swimming Pools And Showers"
                    : ""
                }
                onFocus={() => setNumOfInsideSwimmingPoolsAndShowersFocus(true)}
                onBlur={() => setNumOfInsideSwimmingPoolsAndShowersFocus(false)}
                onChange={e => {
                  setNumOfInsideSwimmingPoolsAndShowers(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfOpenAirSwimmingPoolsAndShowers"
              isFocus={numOfOpenAirSwimmingPoolsAndShowersFocus}
            >
              <span>
                {arabic == false
                  ? "Num Of Open Air Swimming Pools And Showers:"
                  : ":"}
              </span>
              <input
                type="text"
                id="numOfOpenAirSwimmingPoolsAndShowers"
                value={numOfOpenAirSwimmingPoolsAndShowers}
                placeholder={
                  arabic == false
                    ? "Num Of Open Air Swimming Pools And Showers"
                    : ""
                }
                onFocus={() =>
                  setNumOfOpenAirSwimmingPoolsAndShowersFocus(true)
                }
                onBlur={() =>
                  setNumOfOpenAirSwimmingPoolsAndShowersFocus(false)
                }
                onChange={e => {
                  setNumOfOpenAirSwimmingPoolsAndShowers(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfElectricGenerators"
              isFocus={numOfElectricGeneratorsFocus}
            >
              <span>
                {arabic == false ? "Num Of Electric Generators:" : ":"}
              </span>
              <input
                type="text"
                id="numOfElectricGenerators"
                value={numOfElectricGenerators}
                placeholder={
                  arabic == false ? "Num Of Electric Generators" : ""
                }
                onFocus={() => setNumOfElectricGeneratorsFocus(true)}
                onBlur={() => setNumOfElectricGeneratorsFocus(false)}
                onChange={e => {
                  setNumOfElectricGenerators(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfConferenceRooms"
              isFocus={numOfConferenceRoomsFocus}
            >
              <span>{arabic == false ? "Num Of Conference Rooms:" : ":"}</span>
              <input
                type="text"
                id="numOfConferenceRooms"
                value={numOfConferenceRooms}
                placeholder={arabic == false ? "Num Of Conference Rooms" : ""}
                onFocus={() => setNumOfConferenceRoomsFocus(true)}
                onBlur={() => setNumOfConferenceRoomsFocus(false)}
                onChange={e => {
                  setNumOfConferenceRooms(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="numOfLounges" isFocus={numOfLoungesFocus}>
              <span>{arabic == false ? "Num Of Lounges:" : ":"}</span>
              <input
                type="text"
                id="numOfLounges"
                value={numOfLounges}
                placeholder={arabic == false ? "Num Of Lounges" : ""}
                onFocus={() => setNumOfLoungesFocus(true)}
                onBlur={() => setNumOfLoungesFocus(false)}
                onChange={e => {
                  setNumOfLounges(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfWeddingHalls"
              isFocus={numOfWeddingHallsFocus}
            >
              <span>{arabic == false ? "Num Of Wedding Halls:" : ":"}</span>
              <input
                type="text"
                id="numOfWeddingHalls"
                value={numOfWeddingHalls}
                placeholder={arabic == false ? "Num Of Wedding Halls" : ""}
                onFocus={() => setNumOfWeddingHallsFocus(true)}
                onBlur={() => setNumOfWeddingHallsFocus(false)}
                onChange={e => {
                  setNumOfWeddingHalls(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfMeetingHalls"
              isFocus={numOfMeetingHallsFocus}
            >
              <span>{arabic == false ? "Num Of Meeting Halls:" : ":"}</span>
              <input
                type="text"
                id="numOfMeetingHalls"
                value={numOfMeetingHalls}
                placeholder={arabic == false ? "Num Of Meeting Halls" : ""}
                onFocus={() => setNumOfMeetingHallsFocus(true)}
                onBlur={() => setNumOfMeetingHallsFocus(false)}
                onChange={e => {
                  setNumOfMeetingHalls(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfConferenceHalls"
              isFocus={numOfConferenceHallsFocus}
            >
              <span>{arabic == false ? "Num Of Conference Halls:" : ":"}</span>
              <input
                type="text"
                id="numOfConferenceHalls"
                value={numOfConferenceHalls}
                placeholder={arabic == false ? "Num Of Conference Halls" : ""}
                onFocus={() => setNumOfConferenceHallsFocus(true)}
                onBlur={() => setNumOfConferenceHallsFocus(false)}
                onChange={e => {
                  setNumOfConferenceHalls(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="numOfStaffRooms"
              isFocus={numOfStaffRoomsFocus}
            >
              <span>{arabic == false ? "Num Of Staff Rooms:" : ":"}</span>
              <input
                type="text"
                id="numOfStaffRooms"
                value={numOfStaffRooms}
                placeholder={arabic == false ? "Num Of Staff Rooms" : ""}
                onFocus={() => setNumOfStaffRoomsFocus(true)}
                onBlur={() => setNumOfStaffRoomsFocus(false)}
                onChange={e => {
                  setNumOfStaffRooms(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default HotelsOrResorts
