import React, { useState, useContext } from "react"

import { Amenity, InputsContainer } from "./AddListingProcess.styles"

import {
  SelectContainer,
  InputContainer,
} from "../GlobalComponents/GlobalComponents.styles"

import Select from "react-select"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"

const VacantLand = ({
  landclassification,
  isitneara,
  natureandlocation,
  doyouhaveaccessto,
  variouslegalpossibilities,
  isEdit,
  arabic,
}) => {
  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  const {
    setLandclassification,
    setIsitneara,
    setNatureandlocation,
    setDoyouhaveaccessto,
    setVariouslegalpossibilities,
  } = useContext(context)

  // for focusing
  const [landClassificationFocused, setLandClassificationFocused] =
    useState(false)
  const [isitnearaFocused, setIsitnearaFocused] = useState(false)
  const [natureAndLocationFocused, setNatureAndLocationFocused] =
    useState(false)
  const [doyouhaveaccesstoFocused, setDoyouhaveaccesstoFocused] =
    useState(false)
  const [
    variouslegalpossibilitiesFocused,
    setVariouslegalpossibilitiesFocused,
  ] = useState(false)

  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  return (
    <InputsContainer>
      <div className="container">
        <h2>
          {arabic == false ? "Vacant Land Details" : "عدد الدعاوى الملكية"}
        </h2>
        <div className="row">
          <div className="col-lg-6">
            <SelectContainer
              htmlFor="LandClassification"
              isFocus={landClassificationFocused}
            >
              <div className="content">
                <span>
                  {arabic == false ? "Land Classification:*" : "تصنيف الأرض"}
                </span>
                <select
                  id="LandClassification"
                  value={landclassification ? landclassification : null}
                  onFocus={() => setLandClassificationFocused(true)}
                  onBlur={() => setLandClassificationFocused(false)}
                  onChange={e => setLandclassification(e.target.value)}
                >
                  <option value={landclassification} disabled selected>
                    {landclassification
                      ? landclassification
                      : arabic === false
                      ? "Land Classifiction"
                      : "تصنيف الأرض"}
                  </option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="B1">B1</option>
                  <option value="C">C</option>
                  <option value="C1">C1</option>
                  <option value="C2">C2</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                  <option value="F">F</option>
                  <option value="Other">Other</option>
                </select>
                <input
                  type={landclassification === "other" ? "text" : "hidden"}
                  value={landclassification}
                  onChange={e => setLandclassification(e.target.value)}
                />
              </div>
            </SelectContainer>

            <SelectContainer
              htmlFor="Isitneara"
              isFocus={isitnearaFocused}
              value={isitneara ? isitneara : null}
              onFocus={() => setIsitnearaFocused(true)}
              onBlur={() => setIsitnearaFocused(false)}
              onChange={e => setIsitneara(e.target.value)}
            >
              <div className="content">
                <span>
                  {arabic === false ? "Is it near a:*" : "هل هو قريب من "}
                </span>
                <select id="Isitneara">
                  <option value={isitneara} disabled selected>
                    {isitneara
                      ? isitneara
                      : arabic === false
                      ? "Is it near a"
                      : "هل هو قريب من"}
                  </option>
                  <option value="mosque">
                    {arabic === false ? "Mosque" : "مسجد"}
                  </option>
                  <option value="church">
                    {arabic === false ? "Church" : "كنيسة"}
                  </option>
                  <option value="school">
                    {arabic === false ? "School" : "مدرسة"}
                  </option>
                  <option value="shop/mall">
                    {arabic === false ? "Shop/Mall" : "متجر/مركز تسوق"}
                  </option>
                  <option value="hospital/infirmary">
                    {arabic === false ? "Hospital/Infirmary" : "مستشفى/عيادة"}
                  </option>
                  <option value="pharmacy">
                    {arabic === false ? "Pharmacy" : "صيدلية"}
                  </option>
                  <option value="highvoltageelectricitypost/cable">
                    {arabic === false
                      ? "High voltage electricity post/cable"
                      : "عمود الكهرباء ذو الجهد العالي/كابل"}
                  </option>
                </select>
                <input
                  type={isitneara === "other" ? "text" : "hidden"}
                  value={isitneara}
                  onChange={e => setIsitneara(e.target.value)}
                />
              </div>
            </SelectContainer>

            <SelectContainer
              htmlFor="Natureandlocation"
              isFocus={natureAndLocationFocused}
              value={natureandlocation ? natureandlocation : null}
              onFocus={() => setNatureAndLocationFocused(true)}
              onBlur={() => setNatureAndLocationFocused(false)}
              onChange={e => setNatureandlocation(e.target.value)}
            >
              <div className="content">
                <span>
                  {natureandlocation
                    ? natureandlocation
                    : arabic == false
                    ? "Nature and Location:*"
                    : "الطبيعة والموقع"}
                </span>
                <select id="Natureandlocation">
                  <option value="flat">
                    {arabic === false ? "flat" : "مسطحة"}
                  </option>
                  <option value="sloping">
                    {arabic === false ? "sloping" : "منحدرة"}
                  </option>
                  <option value="rocky">
                    {arabic === false ? "rocky" : "صخري"}
                  </option>
                  <option value="earthy">
                    {arabic === false ? "earthy" : "ترابي"}
                  </option>
                  <option value="mountainview">
                    {arabic === false ? "mountain view" : "منظر على الجبال"}
                  </option>
                  <option value="seaview">
                    {arabic === false ? "sea view" : "مطلة على البحر"}
                  </option>
                  <option value="valleyview">
                    {arabic === false ? "valley view" : "إطلالة على الوادي"}
                  </option>
                  <option value="other">
                    {arabic === false ? "other" : "آخر"}
                  </option>
                </select>
                <input
                  type={natureandlocation === "other" ? "text" : "hidden"}
                  value={natureandlocation}
                  onChange={e => setNatureandlocation(e.target.value)}
                />
              </div>
            </SelectContainer>

            <SelectContainer
              htmlFor="Doyouhaveaccessto"
              isFocus={doyouhaveaccesstoFocused}
              value={doyouhaveaccessto ? doyouhaveaccessto : null}
              onFocus={() => setDoyouhaveaccesstoFocused(true)}
              onBlur={() => setDoyouhaveaccesstoFocused(false)}
              onChange={e => setDoyouhaveaccessto(e.target.value)}
            >
              <div className="content">
                <span>
                  {doyouhaveaccessto
                    ? doyouhaveaccessto
                    : arabic == false
                    ? "Do you have access to:*"
                    : "هل لديك حق الوصول إلى"}
                </span>
                <select id="Doyouhaveaccessto">
                  <option value="" disabled selected>
                    {arabic === false
                      ? "Do you have access to?"
                      : "هل لديك حق الوصول إلى"}
                  </option>
                  <option value="pavedroad">
                    {arabic === false ? "paved road" : "الطرق المعبدة"}
                  </option>
                  <option value="stateelectricity">
                    {arabic === false ? "state electricity" : "كهرباء الدولة"}
                  </option>
                  <option value="statesewer">
                    {arabic === false ? "state sewer" : "الصرف الصحي الدولة"}
                  </option>
                  <option value="statewater">
                    {arabic === false ? "state water" : "مياه الدولة"}
                  </option>
                  <option value="telephone">
                    {arabic === false ? "telephone" : "هاتف"}
                  </option>
                  <option value="other">
                    {arabic === false ? "other" : "آخر"}
                  </option>
                </select>
                <input
                  type={doyouhaveaccessto === "other" ? "text" : "hidden"}
                  value={doyouhaveaccessto}
                  onChange={e => setDoyouhaveaccessto(e.target.value)}
                />
              </div>
            </SelectContainer>

            <SelectContainer
              htmlFor="Variouslegalpossibilities"
              isFocus={variouslegalpossibilitiesFocused}
              value={
                variouslegalpossibilities ? variouslegalpossibilities : null
              }
              onFocus={() => setVariouslegalpossibilitiesFocused(true)}
              onBlur={() => setVariouslegalpossibilitiesFocused(false)}
              onChange={e => setVariouslegalpossibilities(e.target.value)}
            >
              <div className="content">
                <span>
                  {variouslegalpossibilities
                    ? variouslegalpossibilities
                    : arabic == false
                    ? "Various legal possibilities:*"
                    : "مختلف الإمكانيات القانونية"}
                </span>
                <select id="Variouslegalpossibilities">
                  <option value={variouslegalpossibilities} disabled selected>
                    {arabic === false
                      ? "Does anyone have a right?"
                      : "هل من حق؟"}
                  </option>
                  <option value="drillanartesianwaterwell">
                    {arabic === false
                      ? "drill an artesian water well"
                      : "حفر بئر ماء ارتوازي"}
                  </option>
                  <option value="ofway">
                    {arabic === false ? "of way" : "على الطريق"}
                  </option>
                  <option value="other">
                    {arabic === false ? "other" : "آخر"}
                  </option>
                </select>
                <input
                  type={
                    variouslegalpossibilities === "other" ? "text" : "hidden"
                  }
                  value={variouslegalpossibilities}
                  onChange={e => setVariouslegalpossibilities(e.target.value)}
                />
              </div>
            </SelectContainer>
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default VacantLand
