import React, { useContext, useState } from "react"

import {
  AddNewAmenity,
  AmenitiesContainer,
  Amenity,
  InputsContainer,
} from "./AddListingProcess.styles"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"
import { useCollection } from "../../hooks/useCollection"

const Amenities = ({ isEdit, addedAmenities, checkedAmenities, arabic }) => {
  const [newAmenityValue, setNewAmenityValue] = useState("")
  const [amenities, setAmenities] = useState([])

  useCollection("amenity").then(fetchedAmenities => {
    setAmenities(fetchedAmenities)
  })

  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  const { setAddedAmenities, setCheckedAmenities } = useContext(context)

  const amenityToggle = (e, amenity) => {
    if (e.target.checked) {
      checkedAmenities.push(amenity)
      setCheckedAmenities(checkedAmenities)
    } else {
      let newArray = checkedAmenities.filter(checkedAmenity => {
        return checkedAmenity.value !== amenity.value
      })
      setCheckedAmenities(newArray)
    }
  }

  // handling the addition of a new amenity
  const addNewAmenity = () => {
    const newValues = [...addedAmenities]
    const newAmenityVal = newAmenityValue.replace(/\s/g, "").toLowerCase()
    newValues.push({ title: newAmenityValue, value: newAmenityVal })
    setAddedAmenities(newValues)
    setNewAmenityValue("")
  }

  // handling the removal of new amenity
  const removeNewAmmenity = amenity => {
    let newArray = addedAmenities.filter(deletedAmenity => {
      return deletedAmenity.value !== amenity.value
    })
    setAddedAmenities(newArray)
  }

  return (
    <InputsContainer>
      <h2>{arabic == false ? "Amenities" : "وسائل الراحة"}</h2>
      <AmenitiesContainer
        newAmenities={amenities}
        addedAmenities={addedAmenities}
      >
        {amenities &&
          amenities.map(amenity => {
            var checked = checkedAmenities.filter(
              e => e.value === amenity.data.value
            )
            return (
              <Amenity key={amenity.data.value}>
                <input
                  type="checkbox"
                  id={amenity.data.value}
                  value={amenity.data.value}
                  onChange={e => amenityToggle(e, amenity.data)}
                  className={checked.length > 0 ? "checked" : ""}
                />
                <label htmlFor={amenity.data.value}>{amenity.data.title}</label>
              </Amenity>
            )
          })}
        {addedAmenities &&
          addedAmenities.map(amenity => {
            return (
              <Amenity key={amenity.value}>
                <label htmlFor={amenity.value}>{amenity.title}</label>
                <button onClick={() => removeNewAmmenity(amenity)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.916"
                    height="30.761"
                    viewBox="0 0 26.916 30.761"
                  >
                    <path
                      id="Path_186"
                      data-name="Path 186"
                      d="M8.123,1.063A1.921,1.921,0,0,1,9.841,0h7.234a1.921,1.921,0,0,1,1.718,1.063l.433.86h5.768a1.923,1.923,0,1,1,0,3.845H1.923a1.923,1.923,0,1,1,0-3.845H7.69ZM1.869,7.69H24.994V26.916a3.849,3.849,0,0,1-3.845,3.845H5.714a3.882,3.882,0,0,1-3.845-3.845ZM6.675,12.5V25.955a1,1,0,0,0,.961.961.931.931,0,0,0,.961-.961V12.5a.931.931,0,0,0-.961-.961A1,1,0,0,0,6.675,12.5Zm5.768,0V25.955a1,1,0,0,0,.961.961.977.977,0,0,0,1.015-.961V12.5a.977.977,0,0,0-1.015-.961A1,1,0,0,0,12.443,12.5Zm5.822,0V25.955a.961.961,0,0,0,1.923,0V12.5a.961.961,0,1,0-1.923,0Z"
                      fill="#477b11"
                    />
                  </svg>
                </button>
              </Amenity>
            )
          })}
      </AmenitiesContainer>
      <AddNewAmenity>
        <label htmlFor="add">
          {arabic == false ? "ADD NEW AMENITIES" : "أضف وسائل راحة جديدة"}
        </label>
        <div className="add-container">
          <input
            placeholder={
              arabic == false ? "Enter new Ammenity" : "أدخل راحة جديدة"
            }
            type="text"
            id="add"
            value={newAmenityValue}
            onChange={e => {
              setNewAmenityValue(e.target.value)
            }}
            dir={arabic == false ? " " : "rtl"}
          />
          {newAmenityValue && (
            <button onClick={addNewAmenity}>
              {arabic == false ? "ADD AMENITY" : "إضافة وسائل الراحة"}
            </button>
          )}
          {!newAmenityValue && (
            <button onClick={addNewAmenity} disabled>
              {arabic == false ? "ADD AMENITY" : "إضافة وسائل الراحة"}
            </button>
          )}
        </div>
      </AddNewAmenity>
    </InputsContainer>
  )
}

export default Amenities
