import React, { useEffect, useState } from "react"

import { NextPrev, UpperInfo } from "./AddListingProcess.styles"
import Amenities from "./amenities"
import emailjs from "emailjs-com"
import Welcome from "./Welcome"
import Details from "./details"
import Financial from "./financial"
import Information from "./information"
import ListingComplete from "./listingComplete"
import Location from "./location"
import useUserEmail from "../../hooks/useUserEmail"
import HotelsOrResorts from "./hotelsorresorts"
import Loader from "../Loader"

import { db, storage } from "../../firebase/config"
import { addDoc, collection } from "firebase/firestore"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { useAuthContext } from "../../hooks/useAuthContext"

import { ListingContext } from "./context"

import { useCollection } from "../../hooks/useCollection"
import { EmojiWink } from "react-bootstrap-icons"
import VacantLand from "./vacantland"

const AddListingProcessDemo = ({ arabic }) => {
  // for firebase
  const colRef = collection(db, "property")
  const colRefNews = collection(db, "news")

  const { user } = useAuthContext()
  const useruid = "EDR7PvC1wmbGR8hsj7ZnMH4AfZX2"
  const data = useUserEmail(useruid)

  useEffect(() => {}, [data])
  const [listingProcess, updateListingProcess] = useState(9)
  const [isLoading, setIsLoading] = useState(false)

  // constants use States
  const [constants, setConstants] = useState([])
  const [propertyTypeConstants, setPropertyTypeConstants] = useState([])
  const [countryConstants, setCountryConstants] = useState([])
  const [saleOrRentConstant, setSaleOrRentConstant] = useState([])
  const [paymentMethodContants, setPaymentMethodConstants] = useState([])

  const resetAndFinish = async isDraft => {
    // checking to see if there is a title
    if (!listingTitle) {
      alert("Please add a title")
      return
    }

    // checking to see if there is a featured image
    if (!featuredImage) {
      alert("Please add a featured image")
      return
    }

    const uploadThumbnailProcess = () => {
      return new Promise(resolve => {
        if (featuredImage.name) {
          const reference = ref(storage, featuredImagePath)
          uploadBytes(reference, featuredImage).then(() => {
            getDownloadURL(reference).then(url => {
              resolve({ url: url, reference: featuredImagePath })
            })
          })
        } else {
          resolve({ url: featuredImage, reference: featuredImagePath })
        }
      })
    }

    const uploadGalleryProcess = async () => {
      return new Promise(resolve => {
        const gallery = [...galleryImages]
        let processed = 0
        const arrayToBeUploaded = []
        if (gallery.length === 0) {
          resolve([])
        }
        gallery.forEach((image, index) => {
          if (image.name) {
            const reference = ref(storage, galleryImagesPaths[index])
            uploadBytes(reference, image).then(() => {
              getDownloadURL(reference).then(url => {
                arrayToBeUploaded.push({
                  url: url,
                  reference: galleryImagesPaths[index],
                })
                processed++
                if (processed === gallery.length) {
                  resolve(arrayToBeUploaded)
                }
              })
            })
          } else {
            arrayToBeUploaded.push({
              url: galleryImages[index],
              reference: galleryImagesPaths[index],
            })
            processed++
            if (processed === gallery.length) {
              resolve(arrayToBeUploaded)
            }
          }
        })
      })
    }

    // add document
    const addDocument = async () => {
      setIsLoading(true)

      let thumbnailUpload, galleryUpload

      // upload thumbnail first
      uploadThumbnailProcess().then(value => {
        thumbnailUpload = value

        // uploading thumbnail finished upload gallery next
        uploadGalleryProcess().then(value => {
          galleryUpload = value

          const docRef = addDoc(colRef, {
            date: new Date(),
            title: listingTitle,
            type: propertyType,
            type_subcategory: propertyTypeSubcategory,
            description: description,
            added_by: useruid,
            isDraft: isDraft,
            featured_image: thumbnailUpload,
            gallery: galleryUpload,
            country: country,
            district: district,
            caza: caza,
            town: town,
            street_name: street,
            zip_code: zipCode,
            address: address,
            building: building,
            longitude: longitude,
            latitude: latitude,
            num_bedrooms: numBedrooms,
            num_bathrooms: numBathrooms,
            num_balcony: numBalcony,
            num_salons: numSalons,
            num_living_room: numLivingRoom,
            num_dining_rooms: numDiningRooms,
            num_floors: numFloors,
            rent_price: rentPrice,
            apartments_per_floor: apartmentsPerFloor,
            area: area,
            Feet_or_m2: Feetorm2,
            legal_status: legalStatus,
            payment_method: paymentMethod,
            other_payment_method: otherPaymentMethod,
            after_repair_value: afterRepairValue,
            rent_or_sale: saleOrRent,
            rent_price: rentPrice,
            sale_price: salePrice,
            current_rent: currentRent,
            built_tax: builtTax,
            property_management: propertyManagement,
            cap_rate: capRate,
            insurance: insurance,
            gross_cashflow: grossCashflow,
            checkedAmenities: checkedAmenities,
            addedAmenities: addedAmenities,
            selectedCurrency: selectedCurrency,
            PlacedBy: placedBy,
          })
          const docRefNews = addDoc(colRefNews, {
            created_at: new Date(),
            title: listingTitle,
            created_by: "",
            description: description,
            featured_image: thumbnailUpload.url,
            gallery: "",
            id: 0,
            overview: " ",
            topic: "new Listing available",
            topic_title: listingTitle,
          }).then(() => {
            console.log("docRefNews", docRefNews)
            setIsLoading(false)
            emailjs.send(
              "opd_mailing_service",
              "template_2tq2kei",
              {
                image: thumbnailUpload.url,
                description: description,
                email: user.email,
                link: `https://propertypro.vip/find-a-property`,
                emailto: "info@onlinepropertydeals.com",
                number: data.phone_number,
              },
              "g1dJssA6i85wnSF7J"
            )
            emailjs.send(
              "opd_mailing_service",
              "template_2tq2kei",
              {
                image: thumbnailUpload.url,
                description: description,
                email: user.email,
                link: `https://propertypro.vip/find-a-property`,
                emailto: user.email,
                number: data.phone_number,
              },
              "g1dJssA6i85wnSF7J"
            )
            if (isDraft) {
              updateListingProcess(6)
            } else {
              updateListingProcess(7)
            }

            resetValues()
          })
        })
      })
    }

    // reseting
    const resetValues = () => {
      setListingTitle("")
      setPropertyTypeSubcategory({})
      setPropertyType({})
      setDescription("")
      setFeaturedImage(null)
      setGalleryImages([])
      setFeaturedImagePath(null)
      setGalleryImagesPaths([])
      setCountry({})
      setDistrict({})
      setCaza({})
      setTown("")
      setStreet("")
      setZipCode("")
      setAddress("")
      setBuilding("")
      setLongitude("")
      setLatitude("")
      setNumBedrooms("0")
      setNumBathrooms("0")
      setNumSalons("0")
      setNumDiningRooms("0")
      setNumLivingRooms("0")
      setNumBalcony("0")
      setNumFloors("0")
      setApartmentsPerFloor("0")
      setArea("")
      setFeetorm2("")
      setLegalStatus("")
      setPaymentMethod({})
      setOtherPaymentMethod("")
      setAfterRepairValue("")
      setSaleOrRent({})
      setRentPrice("")
      setSalePrice("")
      setCurrentRent("")
      setBuiltTax("")
      setInsurance("")
      setPropertyManagement("")
      setGrossCashFlow("")
      setCapRate("")
      setAddedAmenities([])
      setCheckedAmenities([])
      setSelectedCurrency("")
      setPlacedBy("")
    }

    addDocument()
  }

  /** ----------- for the fields ---------------- */
  // hotesl and resorts
  const [numOfFloors, setNumOfFloors] = useState("0")
  const [numOfReceptionStations, setNumOfReceptionStations] = useState("0")
  const [numOfAdminstrationOffices, setNumOfAdminstrationOffices] =
    useState("0")
  const [numOfKinglslySuits, setNumOfKinglslySuits] = useState("0")
  const [
    areaOfKingslySuitInSMetersOrSFeet,
    setAreaOfKingslySuitInSMetersOrSFeet,
  ] = useState("0")
  const [numOfRegularSuits, setNumOfRegularSuits] = useState("0")
  const [
    areaOfRegularSuitsInSMetersOrSFeet,
    setAreaOfRegularSuitsInSMetersOrSFeet,
  ] = useState("0")
  const [numOfSingleBedroomsWithBath, setNumOfSingleBedroomsWithBath] =
    useState("0")
  const [
    areaOfSingleBedroomsWithBathInSMetersOrSFeet,
    setAreaOfSingleBedroomsWithBathInSMetersOrSFeet,
  ] = useState("0")
  const [numOfDoubleBedroomsWithBath, setNumOfDoubleBedroomsWithBath] =
    useState("0")
  const [
    areaOfDoubleBedroomsWithBathInSMetersOrSFeet,
    setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet,
  ] = useState("0")
  const [numOfChaletsOrSeparateHouses, setNumOfChaletsOrSeparateHouses] =
    useState("0")
  const [
    areaOfChaletsOrSeparateousesInSMetersOrSFeet,
    setAreaOfChaletsOrSeparateousesInSMetersOrSFeet,
  ] = useState("0")
  const [numOfCarParkingLots, setNumOfCarParkingLots] = useState("0")
  const [numOfLifts, setNumOfLifts] = useState("0")
  const [numOfRestaurantsAndCoffeeShops, setNumOfRestaurantsAndCoffeeShops] =
    useState("0")
  const [secutriyCamerasAndGaurds, setSecutriyCamerasAndGaurds] = useState("0")
  const [availabilityOfWater, setAvailabilityOfWater] = useState("0")
  const [availabiltyOfInternetService, setAvailabiltyOfInternetService] =
    useState("0")
  const [availabilityOfGardens, setAvailabilityOfGardens] = useState("0")
  const [
    numOfInsideSwimmingPoolsAndShowers,
    setNumOfInsideSwimmingPoolsAndShowers,
  ] = useState("0")
  const [
    numOfOpenAirSwimmingPoolsAndShowers,
    setNumOfOpenAirSwimmingPoolsAndShowers,
  ] = useState("0")
  const [numOfElectricGenerators, setNumOfElectricGenerators] = useState("0")
  const [numOfConferenceRooms, setNumOfConferenceRooms] = useState("0")
  const [numOfLounges, setNumOfLounges] = useState("0")
  const [numOfWeddingHalls, setNumOfWeddingHalls] = useState("0")
  const [numOfMeetingHalls, setNumOfMeetingHalls] = useState("0")
  const [numOfConferenceHalls, setNumOfConferenceHalls] = useState("0")
  const [numOfStaffRooms, setNumOfStaffRooms] = useState("0")
  // Information useState
  const [listingTitle, setListingTitle] = useState("")
  const [propertyTypeSubcategory, setPropertyTypeSubcategory] = useState({})
  const [propertyType, setPropertyType] = useState({})
  const [description, setDescription] = useState("")
  const [featuredImage, setFeaturedImage] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [featuredImagePath, setFeaturedImagePath] = useState(null)
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([])
  const [placedBy, setPlacedBy] = useState("")

  // location useState
  const [country, setCountry] = useState({})
  const [district, setDistrict] = useState({})
  const [caza, setCaza] = useState({})
  const [town, setTown] = useState("")
  const [street, setStreet] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [address, setAddress] = useState("")
  const [building, setBuilding] = useState("")
  const [longitude, setLongitude] = useState("")
  const [latitude, setLatitude] = useState("")

  // details useState
  const [numBedrooms, setNumBedrooms] = useState("0")
  const [numBathrooms, setNumBathrooms] = useState("0")
  const [numSalons, setNumSalons] = useState("0")
  const [numDiningRooms, setNumDiningRooms] = useState("0")
  const [numLivingRoom, setNumLivingRooms] = useState("0")
  const [numBalcony, setNumBalcony] = useState("0")
  const [numFloors, setNumFloors] = useState("0")
  const [apartmentsPerFloor, setApartmentsPerFloor] = useState("0")
  const [area, setArea] = useState("")
  const [legalStatus, setLegalStatus] = useState("")
  const [paymentMethod, setPaymentMethod] = useState({})
  const [otherPaymentMethod, setOtherPaymentMethod] = useState("")
  const [Feetorm2, setFeetorm2] = useState("")

  // financials useState
  const [afterRepairValue, setAfterRepairValue] = useState("")
  const [saleOrRent, setSaleOrRent] = useState({})
  const [rentPrice, setRentPrice] = useState("")
  const [salePrice, setSalePrice] = useState("")
  const [currentRent, setCurrentRent] = useState("")
  const [builtTax, setBuiltTax] = useState("")
  const [insurance, setInsurance] = useState({})
  const [propertyManagement, setPropertyManagement] = useState({})
  const [grossCashflow, setGrossCashFlow] = useState("")
  const [capRate, setCapRate] = useState("")
  const [selectedCurrency, setSelectedCurrency] = useState("")

  // amenities
  const [addedAmenities, setAddedAmenities] = useState([])
  const [checkedAmenities, setCheckedAmenities] = useState([])

  // to check if drafts exists
  const [sortedDocs, setSortedDocs] = useState([])
  const [createNewListingState, setCreateNewListingState] = useState(false)

  // getting the added by the user properties
  const query = ["added_by", "==", useruid]
  useCollection("property", query).then(documents => {
    if (documents) {
      setSortedDocs(documents)
    }
  })

  // getting all the property constants
  useCollection("property_constants").then(values => {
    if (values) {
      setConstants(values)
    }
  })

  useCollection("countries").then(values => {
    if (values) {
      setCountryConstants(values)
    }
  })

  useCollection("property_type").then(values => {
    if (values) {
      setPropertyTypeConstants(values)
    }
  })

  // fetching the constants and applying
  useEffect(() => {
    constants.forEach(constant => {
      switch (constant.id) {
        case "rent_or_sale":
          setSaleOrRentConstant(constant.data.fields)
          break

        case "payment_method":
          setPaymentMethodConstants(constant.data.fields)
          break

        default:
          break
      }
    })
  }, [constants])

  return (
    <>
      {constants && propertyTypeConstants && (
        <ListingContext.Provider
          value={{
            setListingTitle,
            setPropertyType,
            setPropertyTypeSubcategory,
            setDescription,
            setFeaturedImage,
            setFeaturedImagePath,
            setGalleryImages,
            setGalleryImagesPaths,
            setCountry,
            setDistrict,
            setCaza,
            setTown,
            setStreet,
            setZipCode,
            setAddress,
            setBuilding,
            setLongitude,
            setLatitude,
            setNumBedrooms,
            setNumSalons,
            setNumDiningRooms,
            setNumLivingRooms,
            setNumBalcony,
            setNumFloors,
            setApartmentsPerFloor,
            setLegalStatus,
            setPaymentMethod,
            setOtherPaymentMethod,
            setRentPrice,
            setArea,
            setFeetorm2,
            setNumBathrooms,
            setSalePrice,
            setSaleOrRent,
            setAfterRepairValue,
            setCurrentRent,
            setBuiltTax,
            setGrossCashFlow,
            setPropertyManagement,
            setCapRate,
            setInsurance,
            setAddedAmenities,
            setCheckedAmenities,
            setCreateNewListingState,
            setIsLoading,
            setSelectedCurrency,
            setPlacedBy,
            setNumOfFloors,
            setNumOfReceptionStations,
            setNumOfAdminstrationOffices,
            setNumOfKinglslySuits,
            setAreaOfKingslySuitInSMetersOrSFeet,
            setNumOfRegularSuits,
            setAreaOfRegularSuitsInSMetersOrSFeet,
            setNumOfSingleBedroomsWithBath,
            setAreaOfSingleBedroomsWithBathInSMetersOrSFeet,
            setNumOfDoubleBedroomsWithBath,
            setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet,
            setNumOfChaletsOrSeparateHouses,
            setAreaOfChaletsOrSeparateousesInSMetersOrSFeet,
            setNumOfCarParkingLots,
            setNumOfLifts,
            setNumOfRestaurantsAndCoffeeShops,
            setSecutriyCamerasAndGaurds,
            setAvailabilityOfWater,
            setAvailabiltyOfInternetService,
            setAvailabilityOfGardens,
            setNumOfInsideSwimmingPoolsAndShowers,
            setNumOfOpenAirSwimmingPoolsAndShowers,
            setNumOfElectricGenerators,
            setNumOfConferenceRooms,
            setNumOfLounges,
            setNumOfWeddingHalls,
            setNumOfMeetingHalls,
            setNumOfConferenceHalls,
            setNumOfStaffRooms,
          }}
        >
          {!createNewListingState && (
            <Welcome arabic={arabic} drafts={sortedDocs} />
          )}
          {isLoading && <Loader />}
          {createNewListingState && (
            <div className="container">
              <UpperInfo>
                <div className="text">
                  <h1>{arabic == false ? "Add a Lisitng" : "أضف قائمة"}</h1>
                  <p>
                    {arabic == false
                      ? "Please enter your listing information"
                      : "الرجاء إدخال معلومات القائمة الخاصة بك"}
                  </p>
                </div>
                <div className="save">
                  <button className="save" onClick={() => resetAndFinish(true)}>
                    {arabic == false ? "SAVE & EXIT" : "احفظ المخرج"}
                  </button>
                </div>
              </UpperInfo>
              {listingProcess === 1 && propertyTypeConstants && (
                <>
                  <Information
                    listingTitle={listingTitle}
                    propertyType={propertyType}
                    propertyTypeSubcategory={propertyTypeSubcategory}
                    description={description}
                    featuredImage={featuredImage}
                    featuredImagePath={featuredImagePath}
                    galleryImages={galleryImages}
                    galleryImagesPaths={galleryImagesPaths}
                    property_type_constants={propertyTypeConstants}
                    placedBy={placedBy}
                    isEdit={false}
                    arabic={arabic}
                  />
                  <NextPrev>
                    {(listingTitle &&
                      Object.keys(propertyType).length !== 0 &&
                      Object.keys(propertyTypeSubcategory).length !== 0 &&
                      description &&
                      featuredImage && (
                        <button
                          className="next"
                          onClick={() =>
                            updateListingProcess(listingProcess + 1)
                          }
                        >
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      )) || (
                      <button className="next disabled" disabled>
                        {arabic == false ? "Next" : "التالي"}
                      </button>
                    )}
                  </NextPrev>
                </>
              )}
              {listingProcess === 2 && countryConstants && (
                <>
                  <Location
                    country={country}
                    district={district}
                    caza={caza}
                    town={town}
                    street={street}
                    zipCode={zipCode}
                    address={address}
                    building={building}
                    longitude={longitude}
                    latitude={latitude}
                    country_constants={countryConstants}
                    isEdit={false}
                    arabic={arabic}
                  />
                  <NextPrev>
                    {(Object.keys(country).length !== 0 && address && (
                      <>
                        <button
                          className="prev"
                          onClick={() =>
                            updateListingProcess(listingProcess - 1)
                          }
                        >
                          {arabic == false ? "Back" : "خلف"}
                        </button>
                        <button
                          className="next"
                          onClick={() =>
                            updateListingProcess(listingProcess + 1)
                          }
                        >
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      </>
                    )) || (
                      <>
                        <button
                          className="prev"
                          onClick={() =>
                            updateListingProcess(listingProcess - 1)
                          }
                        >
                          {arabic == false ? "Back" : "خلف"}
                        </button>
                        <button className="next disabled" disabled>
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      </>
                    )}
                  </NextPrev>
                </>
              )}
              {listingProcess === 3 && (
                <>
                  <Details
                    numBedrooms={numBedrooms}
                    numBathrooms={numBathrooms}
                    numSalons={numSalons}
                    numDiningRooms={numDiningRooms}
                    numLivingRoom={numLivingRoom}
                    numBalcony={numBalcony}
                    numFloors={numFloors}
                    apartmentsPerFloor={apartmentsPerFloor}
                    legalStatus={legalStatus}
                    area={area}
                    Feetorm2={Feetorm2}
                    paymentMethod={paymentMethod}
                    otherPaymentMethod={otherPaymentMethod}
                    paymentMethodContants={paymentMethodContants}
                    isEdit={false}
                    arabic={arabic}
                  />
                  <NextPrev>
                    {(numBedrooms &&
                      numBathrooms &&
                      numSalons &&
                      numDiningRooms &&
                      numLivingRoom &&
                      numBalcony &&
                      numFloors &&
                      apartmentsPerFloor &&
                      area &&
                      Feetorm2 &&
                      paymentMethod && (
                        <>
                          <button
                            className="prev"
                            onClick={() =>
                              updateListingProcess(listingProcess - 1)
                            }
                          >
                            {arabic == false ? "Back" : "خلف"}
                          </button>
                          <button
                            className="next"
                            onClick={() =>
                              updateListingProcess(listingProcess + 1)
                            }
                          >
                            {arabic == false ? "Next" : "التالي"}
                          </button>
                        </>
                      )) || (
                      <>
                        <button
                          className="prev"
                          onClick={() =>
                            updateListingProcess(listingProcess - 1)
                          }
                        >
                          {arabic == false ? "Back" : "خلف"}
                        </button>
                        <button className="next disabled" disabled>
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      </>
                    )}
                  </NextPrev>
                </>
              )}
              {listingProcess === 4 && (
                <>
                  <Financial
                    afterRepairValue={afterRepairValue}
                    saleOrRent={saleOrRent}
                    rentPrice={rentPrice}
                    salePrice={salePrice}
                    currentRent={currentRent}
                    builtTax={builtTax}
                    propertyManagement={propertyManagement}
                    grossCashflow={grossCashflow}
                    capRate={capRate}
                    insurance={insurance}
                    isEdit={false}
                    sale_or_rent_constants={saleOrRentConstant}
                    selectedCurrency={selectedCurrency}
                    arabic={arabic}
                  />
                  <NextPrev>
                    {(salePrice || rentPrice) && (
                      <>
                        <button
                          className="prev"
                          onClick={() =>
                            updateListingProcess(listingProcess - 1)
                          }
                        >
                          {arabic == false ? "Back" : "خلف"}
                        </button>
                        <button
                          className="next"
                          onClick={() =>
                            updateListingProcess(listingProcess + 1)
                          }
                        >
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      </>
                    )}
                    {!(salePrice || rentPrice) && (
                      <>
                        <button
                          className="prev"
                          onClick={() =>
                            updateListingProcess(listingProcess - 1)
                          }
                        >
                          {arabic == false ? "Back" : "خلف"}
                        </button>
                        <button className="next disabled" disabled>
                          {arabic == false ? "Next" : "التالي"}
                        </button>
                      </>
                    )}
                  </NextPrev>
                </>
              )}
              {listingProcess === 5 && (
                <>
                  <Amenities
                    addedAmenities={addedAmenities}
                    checkedAmenities={checkedAmenities}
                    arabic={arabic}
                  />
                  <NextPrev>
                    <button
                      className="prev"
                      onClick={() => updateListingProcess(listingProcess - 1)}
                    >
                      {arabic == false ? "Back" : "خلف"}
                    </button>
                    <button
                      className="next"
                      onClick={() => resetAndFinish(false)}
                    >
                      {arabic == false ? "Finish" : "إنهاء"}
                    </button>
                  </NextPrev>
                </>
              )}
              {listingProcess == 6 && (
                <ListingComplete isDraft={true} arabic={arabic} />
              )}
              {listingProcess == 7 && (
                <ListingComplete isDraft={false} arabic={arabic} />
              )}
              {
                // listing 8 is the new listing to display
                // the new functionality of
                // hotelsorresorts.js
                listingProcess == 8 && (
                  <HotelsOrResorts
                    numOfFloors={numOfFloors}
                    numOfReceptionStations={numOfReceptionStations}
                    numOfAdminstrationOffices={numOfAdminstrationOffices}
                    numOfKinglslySuits={numOfKinglslySuits}
                    areaOfKingslySuitInSMetersOrSFeet={
                      areaOfKingslySuitInSMetersOrSFeet
                    }
                    numOfRegularSuits={numOfRegularSuits}
                    areaOfRegularSuitsInSMetersOrSFeet={
                      areaOfRegularSuitsInSMetersOrSFeet
                    }
                    numOfSingleBedroomsWithBath={numOfSingleBedroomsWithBath}
                    areaOfSingleBedroomsWithBathInSMetersOrSFeet={
                      areaOfSingleBedroomsWithBathInSMetersOrSFeet
                    }
                    numOfDoubleBedroomsWithBath={numOfDoubleBedroomsWithBath}
                    areaOfDoubleBedroomsWithBathInSMetersOrSFeet={
                      areaOfDoubleBedroomsWithBathInSMetersOrSFeet
                    }
                    numOfChaletsOrSeparateHouses={numOfChaletsOrSeparateHouses}
                    areaOfChaletsOrSeparateousesInSMetersOrSFeet={
                      areaOfChaletsOrSeparateousesInSMetersOrSFeet
                    }
                    numOfCarParkingLots={numOfCarParkingLots}
                    numOfLifts={numOfLifts}
                    numOfRestaurantsAndCoffeeShops={
                      numOfRestaurantsAndCoffeeShops
                    }
                    secutriyCamerasAndGaurds={secutriyCamerasAndGaurds}
                    availabilityOfWater={availabilityOfWater}
                    availabiltyOfInternetService={availabiltyOfInternetService}
                    availabilityOfGardens={availabilityOfGardens}
                    numOfInsideSwimmingPoolsAndShowers={
                      numOfInsideSwimmingPoolsAndShowers
                    }
                    numOfOpenAirSwimmingPoolsAndShowers={
                      numOfOpenAirSwimmingPoolsAndShowers
                    }
                    numOfElectricGenerators={numOfElectricGenerators}
                    numOfConferenceRooms={numOfConferenceRooms}
                    numOfLounges={numOfLounges}
                    numOfWeddingHalls={numOfWeddingHalls}
                    numOfMeetingHalls={numOfMeetingHalls}
                    numOfConferenceHalls={numOfConferenceHalls}
                    numOfStaffRooms={numOfStaffRooms}
                    isEdit={false}
                    arabic={arabic}
                  />
                )
              }
              {listingProcess == 9 && (
                <VacantLand
                  numOfFloors={numOfFloors}
                  numOfReceptionStations={numOfReceptionStations}
                  numOfAdminstrationOffices={numOfAdminstrationOffices}
                  numOfKinglslySuits={numOfKinglslySuits}
                  areaOfKingslySuitInSMetersOrSFeet={
                    areaOfKingslySuitInSMetersOrSFeet
                  }
                  numOfRegularSuits={numOfRegularSuits}
                  areaOfRegularSuitsInSMetersOrSFeet={
                    areaOfRegularSuitsInSMetersOrSFeet
                  }
                  numOfSingleBedroomsWithBath={numOfSingleBedroomsWithBath}
                  areaOfSingleBedroomsWithBathInSMetersOrSFeet={
                    areaOfSingleBedroomsWithBathInSMetersOrSFeet
                  }
                  numOfDoubleBedroomsWithBath={numOfDoubleBedroomsWithBath}
                  areaOfDoubleBedroomsWithBathInSMetersOrSFeet={
                    areaOfDoubleBedroomsWithBathInSMetersOrSFeet
                  }
                  numOfChaletsOrSeparateHouses={numOfChaletsOrSeparateHouses}
                  areaOfChaletsOrSeparateousesInSMetersOrSFeet={
                    areaOfChaletsOrSeparateousesInSMetersOrSFeet
                  }
                  numOfCarParkingLots={numOfCarParkingLots}
                  numOfLifts={numOfLifts}
                  numOfRestaurantsAndCoffeeShops={
                    numOfRestaurantsAndCoffeeShops
                  }
                  secutriyCamerasAndGaurds={secutriyCamerasAndGaurds}
                  availabilityOfWater={availabilityOfWater}
                  availabiltyOfInternetService={availabiltyOfInternetService}
                  availabilityOfGardens={availabilityOfGardens}
                  numOfInsideSwimmingPoolsAndShowers={
                    numOfInsideSwimmingPoolsAndShowers
                  }
                  numOfOpenAirSwimmingPoolsAndShowers={
                    numOfOpenAirSwimmingPoolsAndShowers
                  }
                  numOfElectricGenerators={numOfElectricGenerators}
                  numOfConferenceRooms={numOfConferenceRooms}
                  numOfLounges={numOfLounges}
                  numOfWeddingHalls={numOfWeddingHalls}
                  numOfMeetingHalls={numOfMeetingHalls}
                  numOfConferenceHalls={numOfConferenceHalls}
                  numOfStaffRooms={numOfStaffRooms}
                  isEdit={false}
                  arabic={arabic}
                />
              )}
            </div>
          )}
        </ListingContext.Provider>
      )}
    </>
  )
}

export default AddListingProcessDemo
