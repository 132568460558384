import styled from "styled-components"

export const UpperInfo = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h1 {
    font-size: 1.4rem;
  }
  p {
    margin: 0;
  }

  button.save {
    background: none;
    border: none;
    color: var(--lightGreen);
    font-size: 0.9rem;
  }
`

export const GalleryUploader = styled.div`
  .dropzone {
    margin-bottom: 20px;
    border: dashed 1px #dddddd;
    div {
      justify-content: center;
    }
    span {
      span {
        color: var(--lightGreen);
        font-weight: bold;
        text-decoration: none;
      }
    }

    svg {
      display: none;
    }
    .file-types {
      display: none;
    }
  }
`

export const Gallery = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .featured-image-upload {
    margin-bottom: 20px;
  }

  .thumbnail-container {
    width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;

    &:hover {
      .thumbnail-overlay {
        display: flex;
      }
    }

    .thumbnail-overlay {
      position: absolute;
      top: 0;
      display: none;
      background-color: rgba(225, 255, 255, 0.5);
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
`

export const NextPrev = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  padding-right: 10px;
  padding-bottom: 30px;

  .next {
    background-color: var(--lightGreen);
    padding: 6px 40px;
    color: white;
    border-radius: 5px;
    border: none;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .next.disabled {
    opacity: 50%;
  }

  .prev {
    background: none;
    border-radius: 5px;
    padding: 5px 40px;
    color: var(--lightGreen);
    border: 1px solid var(--lightGreen);

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const InputsContainer = styled.div`
  padding: 40px 0;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
`

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Amenity = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  label {
    word-wrap: break-word;
  }

  input {
    appearance: none;
    border: 1px solid #dddddd;
    min-width: 30px;
    height: 30px;
    border-radius: 5px;

    &:checked {
      position: relative;
      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        top: 4px;
        left: 4px;
        background-color: var(--lightGreen);
        border-radius: 5px;
      }
    }
  }

  input.checked {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      top: 4px;
      left: 4px;
      background-color: var(--lightGreen);
      border-radius: 5px;
    }
  }

  button {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }
`

export const AddNewAmenity = styled.div`
  .add-container {
    display: flex;
    gap: 5px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    input {
      height: 50px;
      padding: 0 10px;
      background: none;
      border: 1px solid #dddddd;
      border-radius: 5px;

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: var(--lightGreen);
      color: white;
      padding: 10px 30px;
      border: 1px solid var(--lightGreen);
      border-radius: 5px;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
`

export const Complete = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .content {
    .loader {
      border: 16px solid #f3f3f3; /* Light grey */
      border-top: 16px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    background-color: white;
    padding: 50px 70px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    border-radius: 10px;

    h4 {
      font-weight: normal;
    }

    svg {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
    a {
      color: var(--lightGreen);
      text-decoration: none;
    }
  }
`
export const RightContent = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--darkBlue);
  width: 30%;

  @media (max-width: 992px) {
    display: none;
  }
`

export const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  .login-image {
    width: 155%;
    max-width: 800px;
    position: absolute;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);
  }
`

export const LeftContent = styled.div`
  padding: 3rem 0;
  h1 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  button.new.disabled {
    opacity: 80%;
    cursor: not-allowed;
  }
  button.new {
    display: block;
    width: 100%;
    border: 1px solid var(--lightGreen);
    color: var(--lightGreen);
    padding: 10px;
    background: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
  }
  .draft-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 30px 0;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;

    .draft-content {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        border-radius: 14px;
        max-width: 180px;
        max-height: 180px;
        object-fit: cover;
      }
    }

    .draft-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      button.delete {
        background: none;
        padding: 10px 20px;
        border: none;
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--lightGreen);
      }

      a.continue {
        background-color: var(--lightGreen);
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        color: white;
        text-decoration: none;
      }
    }
  }
`
