import { Link } from "gatsby"
import React from "react"

import { Complete } from "./AddListingProcess.styles"

const ListingComplete = ({ isDraft,arabic }) => {
  return (
    <Complete>
      <div className="content">
        {isDraft && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60.039"
              height="45.06"
              viewBox="0 0 60.039 45.06"
            >
              <path
                id="Path_162"
                data-name="Path 162"
                d="M22.362,42.423,9.928,29.989a3.573,3.573,0,1,0-5.052,5.052L19.854,50.02a3.569,3.569,0,0,0,5.052,0L62.818,12.108a3.573,3.573,0,1,0-5.052-5.052Z"
                transform="translate(-3.828 -6.008)"
                fill="#477b11"
              />
            </svg>
            <p>{arabic==false?'Your listing has been saved as a draft':'تم حفظ قائمتك كمسودة'}</p>
            <Link to="/">{arabic==false?'GO TO HOMEPAGE':'اذهب إلى الصفحة الرئيسية'}</Link>
          </>
        )}
        {!isDraft && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60.039"
              height="45.06"
              viewBox="0 0 60.039 45.06"
            >
              <path
                id="Path_162"
                data-name="Path 162"
                d="M22.362,42.423,9.928,29.989a3.573,3.573,0,1,0-5.052,5.052L19.854,50.02a3.569,3.569,0,0,0,5.052,0L62.818,12.108a3.573,3.573,0,1,0-5.052-5.052Z"
                transform="translate(-3.828 -6.008)"
                fill="#477b11"
              />
            </svg>
            <h4>{arabic==false?'Congratulations':'تهانينا'}</h4>
            <p>{arabic==false?'Your listing has been created':'تم إنشاء قائمتك'}</p>
            <Link to="/">{arabic==false?'GO TO HOMEPAGE':'اذهب إلى الصفحة الرئيسية'}</Link>
          </>
        )}
      </div>
    </Complete>
  )
}

export default ListingComplete
