import React, { useState, useContext, useEffect } from "react"

import countrys from "../../assets/countriesStates.json"

import { InputsContainer } from "./AddListingProcess.styles"
import Select from "react-select"

// consts
import { StaticImage } from "gatsby-plugin-image"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"

import {
  SelectContainer,
  InputContainer,
} from "../GlobalComponents/GlobalComponents.styles"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../../firebase/config"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

//country json

const Location = ({
  country,
  district,
  caza,
  town,
  street,
  Governate,
  address,
  building,
  isEdit,
  longitude,
  latitude,
  country_constants,
  arabic,
}) => {
  // setting the context
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }

  // settng the country constants right
  if (country_constants) {
    country_constants = country_constants.map(country => {
      return country.data
    })
  }

  const {
    setCountry,
    setDistrict,
    setCaza,
    setTown,
    setStreet,
    setGovernate,
    setAddress,
    setBuilding,
    setLongitude,
    setLatitude,
  } = useContext(context)

  // for focusing
  const [countryFocused, setCountryFocused] = useState(false)
  const [streetFocus, setStreetFocus] = useState(false)
  const [cazaFocus, setCazaFocus] = useState(false)
  const [districtFocus, setDistrictFocus] = useState(false)
  const [townFocus, setTownFocus] = useState(false)
  const [GovernateFocus, setGovernateFocus] = useState(false)
  const [addressFocus, setAddressFocus] = useState(false)
  const [buildingFocus, setBuildingFocus] = useState(false)
  const [longitudeFocus, setLongitudeFocus] = useState(false)
  const [latitudeFocus, setLatitudeFocus] = useState(false)

  // for the lebanese constants
  const [districtConstants, setDistrictConstants] = useState([])
  const [cazaConstants, setCazaConstants] = useState([])
  // map
  //map initial lg and la
  const containerStyle = {
    width: "400px",
    height: "400px",
  }

  const center = {
    lat: 33.8547,
    lng: 35.8623,
  }
  const [markers, setMarkers] = useState([])
  // on click of map
  const onMapClick = e => {
    setMarkers([
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    ])
    setLongitude(e.latLng.lng())
    setLatitude(e.latLng.lat())
  }
  // end of map data and function

  // for setting the governates (previously named districts) when the user chooses a country
  const handleCountrySelected = e => {
    if (e.value === "lebanon") {
      getDocs(collection(db, "governates")).then(docs => {
        var docsArray = []
        docs.forEach(doc => {
          docsArray.push({ label: doc.data().label, value: doc.data().value })
        })
        setDistrictConstants(docsArray)
      })
    } else {
      setDistrictConstants({})
      setDistrict({})
      setCountry({})
      setCazaConstants({})
    }
    setCountry({ value: e.value, label: e.label })
  }

  // for setting the cazas when a user chooses a governate
  const handleDistrictSelected = e => {
    const q = query(collection(db, "governates"), where("value", "==", e.value))
    setCaza({})
    getDocs(q).then(docs => {
      docs.forEach(doc => {
        setCazaConstants(doc.data().cazas)
      })
    })
    setDistrict({ label: e.label, value: e.value })
  }

  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }
  const handleGovernateSelected = e => {
    const q = query(collection(db, "governates"), where("value", "==", e.value))
    setCaza({})
    getDocs(q).then(docs => {
      docs.forEach(doc => {
        setCazaConstants(doc.data().cazas)
      })
    })
    setDistrict({ label: e.label, value: e.value })
  }

  const tabel = countrys.map(type => {
    return { value: type.name, label: type.name }
  })

  const districtConstantss = [
    { value: "beirut", label: arabic == false ? "Beirut" : "بيروت" },
    { value: "Aley", label: arabic == false ? "Aley" : "عاليه" },
    { value: "Baabda", label: arabic == false ? "Baabda" : "بعبدا" },
    { value: "Chouf", label: arabic == false ? "Chouf" : "الشوف" },
    { value: "Keserwan", label: arabic == false ? "Keserwan" : "كسروان" },
    { value: "Matn", label: arabic == false ? "Matn" : "المتن" },
    { value: "Akkar", label: arabic == false ? "Akkar" : "عكار" },
    { value: "Batroun", label: arabic == false ? "Batroun" : "البترون" },
    { value: "Bsharri", label: arabic == false ? "Bsharri" : "بشري" },
    { value: "Koura", label: arabic == false ? "Koura" : "الكورة" },
    {
      value: "Miniyeh-Danniye",
      label: arabic == false ? "Miniyeh-Danniye" : "المنية - الضنية",
    },
    { value: "Tripoli", label: arabic == false ? "Tripoli" : "طرابلس" },
    { value: "Zgharta", label: arabic == false ? "Zgharta" : "زغرتا" },
    { value: "Baalbek", label: arabic == false ? "Baalbek" : "بعلبك" },
    { value: "Hermel", label: arabic == false ? "Hermel" : "الهرمل" },
    { value: "Rashaya", label: arabic == false ? "Rashaya" : "راشيا" },
    {
      value: "West Beqaa",
      label: arabic == false ? "West Beqaa" : "البقاع الغربي",
    },
    { value: "Jezzine", label: arabic == false ? "Jezzine" : "جزين" },
    { value: "Sidon", label: arabic == false ? "Sidon" : "صيدا" },
    { value: "Tyre", label: arabic == false ? "Tyre" : "صور" },
    { value: "Bint Jbeil", label: arabic == false ? "Bint Jbeil" : "بنت جبيل" },
    { value: "Hasbaya", label: arabic == false ? "Hasbaya" : "حاصبيا" },
    { value: "Marjeyoun", label: arabic == false ? "Marjeyoun" : "مرجعيون" },
    { value: "Nabatieh", label: arabic == false ? "Nabatieh" : "النبطية" },
  ]
  const governatesConstantss = [
    { value: "beirut", label: arabic == false ? "Beirut" : "بيروت" },
    {
      value: "mount-lebanon",
      label: arabic == false ? "Mount Lebanon" : "جبل لبنان",
    },
    {
      value: "south-lebanon",
      label: arabic == false ? "South Lebanon" : "جنوب لبنان",
    },
    { value: "nabatieh", label: arabic == false ? "Nabatieh" : "النبطية" },
    {
      value: "north-lebanon",
      label: arabic == false ? "North Lebanon" : "شمال لبنان",
    },
    { value: "beqaa", label: arabic == false ? "Beqaa" : "البقاع" },
    { value: "akkar", label: arabic == false ? "Akkar" : "عكار" },
    {
      value: "baalbek-hermel",
      label: arabic == false ? "Baalbek-Hermel" : "بعلبك الهرمل",
    },
  ]

  return (
    <InputsContainer>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>{arabic == false ? "Location" : "موقع"}</h2>
            <SelectContainer htmlFor="country" isFocus={countryFocused}>
              <div className="content">
                <span>{arabic == false ? "Country:*" : "دولة:*"}</span>
                <Select
                  options={tabel}
                  isSearchable={false}
                  isClearable={false}
                  placeholder={
                    arabic == false ? "Select a country" : "اختر دولة"
                  }
                  id="country"
                  value={Object.keys(country).length !== 0 ? country : null}
                  styles={selectStyles}
                  onFocus={() => setCountryFocused(true)}
                  onBlur={() => setCountryFocused(false)}
                  onChange={e => handleCountrySelected(e)}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            {Object.keys(caza).length !== 0 && (
              <InputContainer htmlFor="town" isFocus={townFocus}>
                <span>
                  {arabic == false
                    ? "Town/City/Village:"
                    : "البلدة / المدينة / القرية:"}
                </span>
                <input
                  type="text"
                  id="town"
                  value={town}
                  placeholder={
                    arabic == false
                      ? "Enter Town/city/village name"
                      : "أدخل اسم المدينة / المدينة / القرية"
                  }
                  onFocus={() => setTownFocus(true)}
                  onBlur={() => setTownFocus(false)}
                  onChange={e => setTown(e.target.value)}
                  dir={arabic == false ? " " : "rtl"}
                />
              </InputContainer>
            )}
            {country.value === "lebanon" && (
              <SelectContainer htmlFor="district" isFocus={districtFocus}>
                <div className="content">
                  <span>{arabic == false ? "Governate:" : "حكم:"}</span>
                  <Select
                    options={districtConstantss}
                    isSearchable={false}
                    isClearable={false}
                    placeholder={
                      arabic == false ? "Select a district" : "حدد منطقة"
                    }
                    id="district"
                    value={Object.keys(district).length !== 0 ? district : null}
                    styles={selectStyles}
                    onFocus={() => setDistrictFocus(true)}
                    onBlur={() => setDistrictFocus(false)}
                    onChange={e => handleDistrictSelected(e)}
                  />
                </div>
                <div className="arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.564"
                    height="7.818"
                    viewBox="0 0 13.564 7.818"
                  >
                    <path
                      id="XMLID_225_"
                      d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                      transform="translate(0.564 -74.5)"
                      fill="#477b11"
                      stroke="#477b11"
                      strokeWidth="1"
                    />
                  </svg>
                </div>
              </SelectContainer>
            )}
            <InputContainer htmlFor="street" isFocus={streetFocus}>
              <span>{arabic == false ? "Street name:" : "اسم الشارع:"}</span>
              <input
                type="text"
                id="street"
                value={street}
                placeholder={
                  arabic == false ? "Enter street name" : "أدخل اسم الشارع"
                }
                onFocus={() => setStreetFocus(true)}
                onBlur={() => setStreetFocus(false)}
                onChange={e => setStreet(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            {/* <InputContainer htmlFor="zip" isFocus={zipFocus}>
              <span>{arabic == false ? "Zip code:" : "الرمز البريدي:"}</span>
              <input
                type="text"
                id="zip"
                value={zipCode}
                placeholder={
                  arabic == false ? "Enter zip code" : "أدخل الرمز البريدي"
                }
                onFocus={() => setZipFocused(true)}
                onBlur={() => setZipFocused(false)}
                onChange={e => setZipCode(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer> */}
            {/* <SelectContainer htmlFor="caza" isFocus={cazaFocus}>
                <div className="content">
                  <span>{arabic == false ? "Caza:" : "مطاردة:"}</span>
                  <Select
                    options={cazaConstants}
                    isSearchable={false}
                    isClearable={false}
                    placeholder={
                      arabic == false ? "Select a caza" : "حدد البحث"
                    }
                    id="caza"
                    value={Object.keys(caza).length !== 0 ? caza : null}
                    styles={selectStyles}
                    onFocus={() => setCazaFocus(true)}
                    onBlur={() => setCazaFocus(false)}
                    onChange={e => setCaza({ value: e.value, label: e.label })}
                  />
                </div>
                <div className="arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.564"
                    height="7.818"
                    viewBox="0 0 13.564 7.818"
                  >
                    <path
                      id="XMLID_225_"
                      d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                      transform="translate(0.564 -74.5)"
                      fill="#477b11"
                      stroke="#477b11"
                      strokeWidth="1"
                    />
                  </svg>
                </div>
              </SelectContainer> */}

            <SelectContainer htmlFor="Governate" isFocus={GovernateFocus}>
              <div className="content">
                <span>{arabic == false ? "Governate:" : "حدد المحافظة"}</span>
                <Select
                  options={governatesConstantss}
                  isSearchable={false}
                  isClearable={false}
                  placeholder={
                    arabic == false ? "Select a Governate" : "حدد المحافظة"
                  }
                  id="Governate"
                  value={Governate}
                  styles={selectStyles}
                  onFocus={() => setGovernateFocus(true)}
                  onBlur={() => setGovernateFocus(false)}
                  onChange={e => handleGovernateSelected(e)}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>

            <SelectContainer htmlFor="district" isFocus={districtFocus}>
              <div className="content">
                <span>{arabic == false ? "district:" : "القضاء:"}</span>
                <Select
                  options={districtConstantss}
                  isSearchable={false}
                  isClearable={false}
                  placeholder={
                    arabic == false ? "Select a district" : "حدد القضاء"
                  }
                  id="district"
                  value={Object.keys(district).length !== 0 ? district : null}
                  styles={selectStyles}
                  onFocus={() => setDistrictFocus(true)}
                  onBlur={() => setDistrictFocus(false)}
                  onChange={e => handleDistrictSelected(e)}
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>

            <InputContainer htmlFor="address" isFocus={addressFocus}>
              <span>{arabic == false ? "Address:*" : "تبوك:*"}</span>
              <input
                type="text"
                id="address"
                value={address}
                placeholder={arabic == false ? "Enter address" : "أدخل العنوان"}
                onFocus={() => setAddressFocus(true)}
                onBlur={() => setAddressFocus(false)}
                onChange={e => setAddress(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="building" isFocus={buildingFocus}>
              <span>{arabic == false ? "Building:" : "مبنى:"}</span>
              <input
                type="text"
                id="building"
                value={building}
                placeholder={
                  arabic == false ? "Enter building name" : "أدخل اسم المبنى"
                }
                onFocus={() => setBuildingFocus(true)}
                onBlur={() => setBuildingFocus(false)}
                onChange={e => setBuilding(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="latitude" isFocus={longitudeFocus}>
              <span>{arabic == false ? "Longitude:" : "خط الطول:"}</span>
              <input
                type="text"
                id="longitude"
                value={longitude}
                placeholder={
                  arabic == false ? "Enter longitude" : "أدخل خط الطول"
                }
                onFocus={() => setLongitudeFocus(true)}
                onBlur={() => setLongitudeFocus(false)}
                onChange={e => setLongitude(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="latitude" isFocus={latitudeFocus}>
              <span>{arabic == false ? "Latitude:" : "خط العرض:"}</span>
              <input
                type="text"
                id="latitude"
                value={latitude}
                placeholder={
                  arabic == false ? "Enter latitude" : "أدخل خط العرض"
                }
                onFocus={() => setLatitudeFocus(true)}
                onBlur={() => setLatitudeFocus(false)}
                onChange={e => setLatitude(e.target.value)}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
          <div className="col-lg-6">
            {/* <StaticImage
              src="../../assets/images/map-image.png"
              alt="map"
              placeholder="tracedSVG"
              layout="constrained"
            /> */}
            {/* google map */}
            <LoadScript googleMapsApiKey="AIzaSyDz_hzC_IghxjnBjW-4wSRFqMgZLThvyuk">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={8}
                onClick={onMapClick}
              >
                {markers.map(marker => (
                  <Marker
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                  />
                ))}
                <></>
              </GoogleMap>
            </LoadScript>
            {/* end google map */}
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default Location
