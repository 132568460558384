import React, { useState, useContext } from "react"

import { InputsContainer } from "./AddListingProcess.styles"

import {
  SelectContainer,
  InputContainer,
} from "../GlobalComponents/GlobalComponents.styles"

import Select from "react-select"

import { ListingContext } from "./context"
import { EditListingContext } from "../EditListingProcess/context"

const Details = ({
  numBedrooms,
  numBathrooms,
  numSalons,
  numDiningRooms,
  numLivingRoom,
  numBalcony,
  numFloors,
  WhichFloor,
  apartmentsPerFloor,
  legalStatus,
  area,
  paymentMethod,
  paymentMethodContants,
  otherPaymentMethod,
  isEdit,
  arabic,
  Feetorm2,
}) => {
  // setting the context first
  let context = {}
  if (isEdit) {
    context = EditListingContext
  } else {
    context = ListingContext
  }
  const {
    setNumBedrooms,
    setNumBathrooms,
    setNumSalons,
    setNumDiningRooms,
    setNumLivingRooms,
    setNumBalcony,
    setNumFloors,
    setApartmentsPerFloor,
    setArea,
    setLegalStatus,
    setPaymentMethod,
    setOtherPaymentMethod,
    setFeetorm2,
    setWhichFloor,
  } = useContext(context)

  // for focusing
  const [numBedroomsFocus, setNumBedroomsFocus] = useState(false)
  const [bathroomsFocus, setBathroomsFocus] = useState(false)
  const [salonsFocus, setSalonsFocus] = useState(false)
  const [diningRoomsFocus, setDiningRoomsFocus] = useState(false)
  const [livingRoomsFocus, setLivingRoomsFocus] = useState(false)
  const [balconyFocus, setBalconyFocus] = useState(false)
  const [floorsFocus, setFloorsFocus] = useState(false)
  const [apartmentsPerFloorFocus, setApartmentsPerFloorFocus] = useState(false)
  const [areaFocus, setAreaFocus] = useState(false)
  const [legalStatusFocus, setLegalStatusFocus] = useState(false)
  const [paymentMethodFocus, setPaymentMethodFocus] = useState(false)
  const [otherPaymentMethodFocus, setOtherPaymentMethodFocus] = useState(false)
  const [Feetorm2Focus, setFeetorm2Focus] = useState(false)
  const [WhichFloorFocus, setWhichFloorFocus] = useState(false)


  // select styles
  const selectStyles = {
    control: provided => ({
      ...provided,
      border: "none",
      borderColor: "transparent !important",
      boxShadow: "unset",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "10px 0 0",
      outline: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    menu: provided => ({
      ...provided,
      zIndex: "2",
    }),
  }

  return (
    <InputsContainer>
      <div className="container">
        <h2>{arabic == false ? "Property Details" : "تفاصيل اوضح"}</h2>
        <div className="row">
          <div className="col-lg-6">
            <InputContainer htmlFor="bedrooms" isFocus={numBedroomsFocus}>
              <span>
                {arabic == false ? "Number of bedrooms:*" : "عدد غرف النوم:*"}
              </span>
              <input
                type="text"
                id="bedrooms"
                value={numBedrooms}
                placeholder={
                  arabic == false
                    ? "Enter number of bedrooms"
                    : "أدخل عدد عدد غرف النوم"
                }
                onFocus={() => setNumBedroomsFocus(true)}
                onBlur={() => setNumBedroomsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumBedrooms(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="bathrooms" isFocus={bathroomsFocus}>
              <span>
                {arabic == false ? "Number of bathrooms:*" : "عدد الحمامات: *"}
              </span>
              <input
                type="text"
                id="bathrooms"
                value={numBathrooms}
                placeholder={
                  arabic == false
                    ? "Enter number of bathrooms"
                    : "أدخل عدد الحمامات"
                }
                onFocus={() => setBathroomsFocus(true)}
                onBlur={() => setBathroomsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumBathrooms(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="salons" isFocus={salonsFocus}>
              <span>
                {arabic == false ? "Number of Salons:*" : "عدد الصالونات: *"}
              </span>
              <input
                type="text"
                id="salons"
                value={numSalons}
                placeholder={
                  arabic == false
                    ? "Enter number of salons"
                    : "أدخل عدد الصالونات"
                }
                onFocus={() => setSalonsFocus(true)}
                onBlur={() => setSalonsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumSalons(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="dining" isFocus={diningRoomsFocus}>
              <span>
                {arabic == false
                  ? "Number of dining rooms:*"
                  : "عدد غرف الطعام: *"}
              </span>
              <input
                type="text"
                id="dining"
                value={numDiningRooms}
                placeholder={
                  arabic == false
                    ? "Enter number of dining rooms"
                    : "أدخل عدد غرف الطعام"
                }
                onFocus={() => setDiningRoomsFocus(true)}
                onBlur={() => setDiningRoomsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumDiningRooms(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="living" isFocus={livingRoomsFocus}>
              <span>
                {arabic == false
                  ? "Number of Living Rooms:*"
                  : "عدد غرف المعيشة: *"}
              </span>
              <input
                type="text"
                id="living"
                value={numLivingRoom}
                placeholder={
                  arabic == false
                    ? "Enter number of living rooms"
                    : "أدخل عدد غرف المعيشة"
                }
                onFocus={() => setLivingRoomsFocus(true)}
                onBlur={() => setLivingRoomsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumLivingRooms(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="balcony" isFocus={balconyFocus}>
              <span>
                {arabic == false ? "Number of Balconies:*<" : "عدد الشرفات: *"}
              </span>
              <input
                type="text"
                id="balcony"
                value={numBalcony}
                placeholder={
                  arabic == false
                    ? "Enter number of living rooms"
                    : "أدخل عدد غرف المعيشة"
                }
                onFocus={() => setBalconyFocus(true)}
                onBlur={() => setBalconyFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumBalcony(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
          </div>
          <div className="col-lg-6">
            <InputContainer htmlFor="floors" isFocus={floorsFocus}>
              <span>
                {arabic == false ? "Number of Floors:*" : "عدد الطوابق:*"}
              </span>
              <input
                type="text"
                id="floors"
                value={numFloors}
                placeholder={
                  arabic == false
                    ? "Enter number of living rooms"
                    : "أدخل عدد غرف المعيشة"
                }
                onFocus={() => setFloorsFocus(true)}
                onBlur={() => setFloorsFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setNumFloors(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="floors" isFocus={WhichFloorFocus}>
              <span>
                {arabic == false ? "Which Floor:*" : "أي طابق:*"}
              </span>
              <input
                type="text"
                id="whichFloor"
                value={WhichFloor}
                placeholder={
                  arabic == false
                    ? "Which Floor"
                    : "أي طابق"
                }
                onFocus={() => setWhichFloorFocus(true)}
                onBlur={() => setWhichFloorFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setWhichFloor(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer
              htmlFor="apartments"
              isFocus={apartmentsPerFloorFocus}
            >
              <span>
                {arabic == false
                  ? "Apartments per floor:*"
                  : "عدد الشقق في كل طابق: *"}
              </span>
              <input
                type="text"
                id="apartments"
                value={apartmentsPerFloor}
                placeholder={
                  arabic == false
                    ? "Enter number of apartments per floor"
                    : "أدخل عدد الشقق في كل طابق"
                }
                onFocus={() => setApartmentsPerFloorFocus(true)}
                onBlur={() => setApartmentsPerFloorFocus(false)}
                onChange={e => {
                  if (!(e.target.value < 0 || e.target.value > 99)) {
                    setApartmentsPerFloor(e.target.value)
                  }
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <InputContainer htmlFor="area" isFocus={areaFocus}>
              <span>{arabic == false ? "Area:" : "المساحة السكنيّة:"}</span>
              <input
                type="text"
                id="area"
                value={area}
                placeholder={
                  arabic == false
                    ? "Enter area in meters squared"
                    : "أدخل المساحة بالمتر المربع"
                }
                onFocus={() => setAreaFocus(true)}
                onBlur={() => setAreaFocus(false)}
                onChange={e => {
                  setArea(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>

            <InputContainer htmlFor="Feetorm2" isFocus={Feetorm2Focus}>
              <span>{arabic == false ? "Feet or meters squared:" : "قدم أو متر مربع:"}</span>
              <input
                type="text"
                id="Feetorm2"
                value={Feetorm2}
                placeholder={
                  arabic == false
                    ? "Enter Feet or meters squared"
                    : "أدخل قدم أو متر مربع"
                }
                onFocus={() => setFeetorm2Focus(true)}
                onBlur={() => setFeetorm2Focus(false)}
                onChange={e => {
                  setFeetorm2(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>

            <InputContainer htmlFor="legal" isFocus={legalStatusFocus}>
              <span>
                {arabic == false ? "Legal Status:" : "الوضع القانوني:"}
              </span>
              <input
                type="text"
                id="legal"
                value={legalStatus}
                placeholder={
                  arabic == false
                    ? "Enter legal status of property"
                    : "أدخل الوضع القانوني للممتلكات"
                }
                onFocus={() => setLegalStatusFocus(true)}
                onBlur={() => setLegalStatusFocus(false)}
                onChange={e => {
                  setLegalStatus(e.target.value)
                }}
                dir={arabic == false ? " " : "rtl"}
              />
            </InputContainer>
            <SelectContainer
              htmlFor="payment_method"
              isFocus={paymentMethodFocus}
            >
              <div className="content">
                <span>
                  {arabic == false
                    ? "Payment Method:*"
                    : "طريقة الدفع او السداد:*"}
                </span>
                <Select
                  options={paymentMethodContants}
                  isSearchable={false}
                  isClearable={false}
                  value={
                    Object.keys(paymentMethod).length !== 0
                      ? paymentMethod
                      : null
                  }
                  placeholder={
                    arabic == false
                      ? "Please enter payment method"
                      : "الرجاء إدخال طريقة الدفع"
                  }
                  id="payment_method"
                  styles={selectStyles}
                  onFocus={() => setPaymentMethodFocus(true)}
                  onBlur={() => setPaymentMethodFocus(false)}
                  onChange={e =>
                    setPaymentMethod({ label: e.label, value: e.value })
                  }
                />
              </div>
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.564"
                  height="7.818"
                  viewBox="0 0 13.564 7.818"
                >
                  <path
                    id="XMLID_225_"
                    d="M12.334,75.166a.568.568,0,0,0-.8,0l-5.28,5.28L.97,75.166a.568.568,0,0,0-.8.8l5.682,5.682a.568.568,0,0,0,.8,0l5.682-5.682A.568.568,0,0,0,12.334,75.166Z"
                    transform="translate(0.564 -74.5)"
                    fill="#477b11"
                    stroke="#477b11"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            </SelectContainer>
            {paymentMethod.value === "other" && (
              <InputContainer
                htmlFor="otherpayment"
                isFocus={otherPaymentMethodFocus}
              >
                <span>
                  {arabic == false
                    ? "Other payment method:*"
                    : "غيرها من طريقة الدفع:*"}
                </span>
                <input
                  type="text"
                  id="otherpayment"
                  value={otherPaymentMethod}
                  placeholder={
                    arabic == false
                      ? "Enter other payment option"
                      : "أدخل خيار دفع آخر"
                  }
                  onFocus={() => setOtherPaymentMethodFocus(true)}
                  onBlur={() => setOtherPaymentMethodFocus(false)}
                  onChange={e => {
                    setOtherPaymentMethod(e.target.value)
                  }}
                  dir={arabic == false ? " " : "rtl"}
                />
              </InputContainer>
            )}
          </div>
        </div>
      </div>
    </InputsContainer>
  )
}

export default Details
